import React from 'react';
import InputTextField from '../../forms/multiStepsForm/fields/InputTextField';
import useEmotionStyles from '../../../common/useEmotionStyles';
import InfoIcon from '@mui/icons-material/Info';
import {Tooltip} from '@mui/material';
import {Theme} from '@emotion/react';

interface IProps {
  showTooltipDateDebut?: boolean,
  disabled?: boolean,
}

const styles = (theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  marginLeft48: {
    marginLeft: '48px',
  },
  field: {
    'width': '180px',
    'marginRight': '24px',
  },
  tooltip: {
    color: theme.palette.secondary.dark2,
  },
});

export const Dates = (props: IProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={classes.flex}>
      <InputTextField
        className={classes.field}
        name='dateDebut'
        type='date'
        label='Depuis'
        mandatory={false}
        tooltip={props.showTooltipDateDebut ?
            <Tooltip
              className={classes.tooltip}
              disableInteractive
              placement="right"
              title={'En cas d\'absence de date de début, elle sera calculée 1 an avant la date de fin ' +
                '(ou la date du jour si aucune date de fin présente)'}
            >
              <InfoIcon/>
            </Tooltip> :
            <></>
        }
        disabled={props.disabled ?? false}
      />
      <div className={classes.marginLeft48}>
        <InputTextField
          className={classes.field}
          name='dateFin'
          type='date'
          label={'Jusqu\'au'}
          mandatory={false}
          disabled={props.disabled ?? false}
        />
      </div>
    </div>
  )
};
