import React from 'react';
import {ReactComponent as IMAGEIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-image.svg';
import {ReactComponent as AutreFichierIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-autre.svg';
import {ReactComponent as PDFIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-pdf.svg';
import {ReactComponent as DOCIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-doc.svg';
import {ReactComponent as XLSIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-excel.svg';
import {ReactComponent as PPTIcon} from '../../images/icones/icones-extensions-fichier/icn-fichier-ppt.svg';
import useEmotionStyles from '../../common/useEmotionStyles';

/*
* Retourne un icone en fonction de l'extension de fichier passée en paramètre
* - marginRight permet de spécifier la marge à droite de l'icone
*/
export const useIconExtensionFile = (extension: string, marginRight?: string) => {
  const styles = () => ({
    icon: {
      width: '30px',
      height: '30px',
      display: 'block',
      margin: 'auto',
      marginRight: marginRight ?? 'auto',
    },
    iconImage: {
      margin: 'auto 10px auto auto',
      marginRight: marginRight ?? 'auto',
      borderRadius: '50px',
    },
  });
  const classes = useEmotionStyles(styles);

  switch (extension) {
    case 'BMP': case 'JPEG': case 'JPG': case 'GIF': case 'PNG':
      return <IMAGEIcon className={`${classes.icon} ${classes.iconImage}`}/>;
    case 'PDF':
      return <PDFIcon className={`${classes.icon} ${classes.iconDoc}`}/>;
    case 'DOC': case 'DOCX': case 'TXT':
      return <DOCIcon className={`${classes.icon} ${classes.iconDoc}`}/>;
    case 'XLS': case 'XLSX':
      return <XLSIcon className={`${classes.icon} ${classes.iconDoc}`}/>;
    case 'PPT': case 'PPTX': case 'PPS': case 'PPSX':
      return <PPTIcon className={`${classes.icon} ${classes.iconDoc}`}/>;
    default:
      return <AutreFichierIcon className={classes.icon}/>;
  }
};
