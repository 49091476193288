import {ButtonLink} from '../../common/buttons/ButtonLink';
import {ReactComponent as PDFIcon} from '../../../images/icones/icn-pdf.svg';
import {Loader} from '../../common/Loader';
import {ReactComponent as DossierIcon} from '../../../images/icones/icn-dossier.svg';
import React, {useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {IResultLine} from '../../forms/rechercheDocuments/model/model';
import {mapperEDI} from '../../forms/rechercheDocuments/utils/utils';
import {UserContext} from '../../../context/UserContext';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF, GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
} from '@mui/x-data-grid';
import {getDocumentPdf} from '../../../api/dossier';
import {TokenModel} from '../../../model/common/TokenModel';
import {canOpenPdf} from '../../detailsDossier/components/historiqueComponents/Utils';
import {Tooltip, Typography} from '@mui/material';

const base64 = require('base-64');

interface IProps {
  pdfIsPending: boolean
  setPdfIsPending: (pdfIsPending: boolean) => void
  setErrorPDF: (errorPDF: boolean) => void
}

/** Design */
const styles = (theme: Theme) => ({
  headerGrid: {
    backgroundColor: theme.palette.grey.A200,
  },
  docColumn: {
    minWidth: 'fit-content',
    backgroundColor: theme.palette.white.main,
    border: '1px solid ' + theme.palette.link.main,
    borderRadius: '30px',
    width: 'max-content',
    fontSize: '12px !important',
    letterSpacing: '0px',
    opacity: '0.71',
    padding: '5px 12px !important',
  },
  paddingTop5: {
    paddingTop: '5px',
  },
  iconLink: {
    cursor: 'pointer',
    fill: theme.palette.link.main,
  },
  loaderPDF: {
    margin: '10px 0px 0px 5px',
  },
  paddingTop14: {
    paddingTop: '14px',
  },
  checkBoxRADGrid: {
    display: 'grid',
    gridTemplateColumns: '25px 25px',
    alignItems: 'center',
    gridColumnGap: '12px',
  },
  checkBoxRADColumnHeader: {
    fontWeight: 401,
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  hidden: {
    contentVisibility: 'hidden' as const,
  },
  multiLines: {
    whiteSpace: 'normal',
    wordBreak: 'break-word' as const,
    lineHeight: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

export const useGridColumns = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {pdfIsPending, setPdfIsPending, setErrorPDF} = props;
  const {user} = React.useContext(UserContext);

  // State
  const [pdfLineClickedId, setPdfLineClickedId] = useState<string>('');

  // Clic sur le bouton DOC
  const handleClickDoc = (doc: IResultLine) => {
    const tokenEdi = mapperEDI(user, doc);
    const token = base64.encode(JSON.stringify(tokenEdi));
    const url = '/extranet/mission/vue-edi/' + token;
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  // Clic sur le bouton PDF
  const handleClickPDF = (doc: IResultLine) => {
    setErrorPDF(false);
    setPdfLineClickedId(doc.identifier);
    setPdfIsPending(true);
    const urlParams = {
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeGTA: doc.gtaCode,
      codeAbonne: user.codeAbo,
      codeService: user.service,
      referenceSinistre: doc.claim?.trim(),
      numeroMission: doc.mission?.trim(),
    };
    getDocumentPdf(doc.idDoc, urlParams, doc.docType, doc.docSousType ?? '', doc.identifier, false)
        .then((response) => {
        // Create a Blob from the PDF Stream
          const file = new Blob([response], {type: 'application/pdf'});
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          // Open the URL on new Window
          if (window.navigator.msSaveOrOpenBlob) {
          // IE11
            window.navigator.msSaveOrOpenBlob(file, 'document.pdf')
          } else {
            window.open(fileURL)
          }
          setPdfIsPending(false)
        }).catch(() => {
          setErrorPDF(true);
        }).finally(() => {
          setPdfIsPending(false)
        })
  };

  // Clic sur le bouton DOSSIER
  const handleClickFolder = (doc: IResultLine) => {
    const tokenMission: TokenModel = {
      referenceSinistre: doc.claim,
      numeroMission: doc.mission,
      codeGTA: doc.gtaCode,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
      immatriculation: doc.immatriculation,
      isDossierComplet: false,
      isSearchDocumentsContext: true,
    };
    const token = base64.encode(JSON.stringify(tokenMission));
    const url = '/extranet/dossiers/mission/' + token;
    window.open(url, '_blank');
  };

  const columns: GridColDef[] = [
    {field: 'doc', headerName: 'Doc', headerClassName: classes.headerGrid, sortable: false, align: 'center',
      headerAlign: 'center', flex: 0.75,
      renderCell: (params) => (
        <Tooltip title="Consulter la vue EDI du message">
          <span>
            <ButtonLink
              id={'bouton-document-'.concat(params.row.identifier.replace(/\s/g, ''))}
              className={classes.docColumn}
              libelle={params.row.doc}
              isBackgroundHover={false}
              isDisableRipple={true}
              onClick={() => handleClickDoc(params.row)}
            />
          </span>
        </Tooltip>
      ),
    },

    {field: 'sousDoc', headerName: 'Sous-doc', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1,
      renderCell: (params) => (
        <div className={classes.multiLines}>
          {params.value}
        </div>
      ),
    },

    {field: 'pdf', headerName: 'PDF', headerClassName: classes.headerGrid, headerAlign: 'center', sortable: false,
      align: 'center', width: 55,
      renderCell: (params) => (
        <>
          {canOpenPdf(params.row.docType, params.row.docSousType, params.row.archive, params.row.docVisualisable) &&
          <div className={classes.paddingTop5}>
            {(!pdfIsPending || pdfIsPending && params.row.identifier !== pdfLineClickedId) &&
              <Tooltip title="Consulter le PDF du message">
                <PDFIcon
                  className={classes.iconLink}
                  id={'bouton-pdf-'.concat(params.row.identifier.replace(/\s/g, ''))}
                  onClick={() => handleClickPDF(params.row)}
                />
              </Tooltip>
            }
            {pdfIsPending && params.row.identifier === pdfLineClickedId &&
                <Loader className={classes.loaderPDF} size={20}/>
            }
          </div>
          }
        </>
      ),
    },

    {field: 'emetteur', headerName: 'Émetteur', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1,
      renderCell: (params) => (
        <div className={classes.multiLines}>
          {params.value}
        </div>
      ),
    },

    {field: 'destinataire', headerName: 'Destinataire', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1,
      renderCell: (params) => (
        <div className={classes.multiLines}>
          {params.value}
        </div>
      ),
    },

    {field: 'libelleStatut', headerName: 'Statut', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 0.9, minWidth: 180,
      renderCell: (params) => (
        <div className={classes.multiLines}>
          {params.value}
        </div>
      ),
    },

    {field: 'claim', headerName: 'Ref sinistre', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1,
    },

    {field: 'mission', headerName: 'Ref mission', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 0.7,
    },

    {field: 'dateReception', headerName: 'Date de réception', headerClassName: classes.headerGrid,
      headerAlign: 'center', sortable: false, align: 'center', flex: 1, minWidth: 160,
    },

    {field: 'dateEmission', headerName: 'Date d\'émission', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', flex: 1, minWidth: 160,
    },

    {field: 'actif', headerName: 'État', headerClassName: classes.headerGrid, headerAlign: 'center', sortable: false,
      align: 'center', flex: 0.8, minWidth: 155,
      renderCell: (params) => (
        <div className={classes.multiLines}>
          {params.value}
        </div>
      ),
    },

    {field: 'dossier', headerName: 'Dossier', headerClassName: classes.headerGrid, headerAlign: 'center',
      sortable: false, align: 'center', width: 75,
      renderCell: (params) => (
        <Tooltip title="Accéder au dossier du message">
          <DossierIcon
            className={`${classes.iconLink} ${classes.paddingTop14}`}
            id={'bouton-dossier-'.concat(params.row.identifier.replace(/\s/g, ''))}
            onClick={() => handleClickFolder(params.row)}
          />
        </Tooltip>
      ),
    },
    {headerClassName: classes.headerGrid, flex: 0.8, ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={classes.checkBoxRADGrid}>
          <Typography className={classes.checkBoxRADColumnHeader}>RAD</Typography>
          <GridHeaderCheckbox {...params} />
        </div>
      ),
      cellClassName: (params: GridCellParams) => (
          !params.row.rad ? classes.hidden : ''
      ),
    },
  ];

  return columns;
};
