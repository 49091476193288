interface IRechercheDocument {
    dateDebut: string
    dateFin: string
    etat: string
    statut: string
    document: string
    sousDocument: string
    numeroFacture: string
    immatriculation: string
}

export const initialValues: IRechercheDocument = {
  dateDebut: '',
  dateFin: '',
  etat: ' ',
  statut: 'STA01',
  document: ' ',
  sousDocument: ' ',
  numeroFacture: '',
  immatriculation: '',
};
