import React, {useState} from 'react';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ReactComponent as CheckIcon} from '../../../images/icones/icn-check.svg';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Form, Formik, FormikValues} from 'formik';
import {initialValues} from './initialValues';
import {schemaValidation} from './schema/schema';
import {Dates} from '../../common/recherche/Dates';
import {SearchFields} from './components/SearchFields';
import {UserContext} from '../../../context/UserContext';
import {ReactComponent as ResetIcon} from '../../../images/icones/Reset.svg';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {Typography} from '@mui/material';
import {DocumentSearchModel} from '../../../model/rechercheDocument/DocumentSearchModel';

interface IProps {
  setSearch: (search: DocumentSearchModel) => void
  setShowResults: (showResults: boolean) => void
}

const styles = (theme: Theme) => ({
  form: {
    position: 'relative' as const,
  },
  marginTop24: {
    marginTop: '24px',
  },
  containerIconReset: {
    position: 'absolute' as const,
    top: 0,
    right: 0,
  },
  iconReset: {
    fill: theme.palette.link.main,
    marginRight: '8px',
  },
  button: {
    textAlign: 'right' as const,
  },
  iconValidate: {
    fill: theme.palette.white.main,
    marginRight: '8px',
  },
  libelle: {
    marginBottom: '16px',
  },
});

export const RechercheDocuments = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {setShowResults, setSearch} = props;
  const {user} = React.useContext(UserContext);
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const onSubmit = (values: FormikValues) => {
    setShowResults(true);
    setShowRefreshButton(true);
    setSearch({
      codeAbo: user.codeAbo,
      numClient: user.numClient,
      codeService: user.service,
      docType: values.document,
      dateDebut: values.dateDebut,
      dateFin: values.dateFin,
      docSousType: values.sousDocument,
      numFacture: values.numeroFacture,
      numImmatriculation: values.immatriculation,
      etat: values.etat,
      statut: values.statut,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schemaValidation}
    >
      {({resetForm}) =>
        <Form className={classes.form}>
          <Typography className={classes.libelle}>{'Date d\'ouverture du dossier'}</Typography>
          <Dates />

          <SearchFields/>

          {showRefreshButton &&
            <div className={classes.containerIconReset}>
              <ButtonLink
                id={'bouton-reinitialiser'}
                libelle={'réinitialiser'}
                isLibelleUpperCase={true}
                icon={<ResetIcon className={classes.iconReset}/>}
                onClick={() => {
                  setShowResults(false)
                  setShowRefreshButton(false)
                  resetForm()
                }}
              />
            </div>
          }

          <div className={`${classes.button} ${classes.marginTop24}`}>
            <ButtonBlue
              id={'bouton-valider'}
              type={'submit'}
              icon={<CheckIcon className={classes.iconValidate}/>}
              libelle={'Valider la recherche'}
            />
          </div>
        </Form>
      }
    </Formik>
  )
};
