import React, {useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Modal, Slide, Typography} from '@mui/material';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import SlideForm from '../../../components/slide/SlideForm';
import InputFilesDragAndDropField
  from '../../../multiStepsForm/fields/inputDragAndDropFilesField/InputFilesDragAndDropField';
import {FormikValues, useFormikContext} from 'formik';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: '0px',
  },
});

const PiecesJointesBloc = () => {
  const classes = useEmotionStyles(styles);
  const [showPj, setShowPj] = useState(false);
  const {values}: FormikValues = useFormikContext();
  const nbPiecesJointes = values.piecesJointes.attachments.length

  const onClosePj = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPj(!showPj)
    }
  };

  const childrenBody = (
    <InputFilesDragAndDropField
      name="piecesJointes"
      onSavePj={() => setShowPj(!showPj)}
      onClosePj={onClosePj}
    />
  )

  const libelleLink = nbPiecesJointes > 0 ? 'Gérer les pièces jointes (' + nbPiecesJointes + ')' :
    'Ajouter des pièces jointes';

  return (
    <>
      <div className={classes.cardContent}>
        <div className={classes.content}>
          <Typography variant={'h3'} className={classes.title}>Pièces Jointes</Typography>
          <ButtonLink
            id={'bouton-ajout-PJ'}
            libelle={libelleLink}
            isLibelleUpperCase={true}
            isLibelleBold={true}
            onClick={() => setShowPj(!showPj)}
          />
        </div>
      </div>
      <Modal
        open={showPj}
        aria-labelledby="modal-pj-OM"
        aria-describedby="modal-formulaire-envoie-messageOM"
      >
        <Slide in={showPj} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          <div>
            <SlideForm
              title={'Pièces Jointes'}
              onClose={onClosePj}
              childrenBody={childrenBody}
            />
          </div>
        </Slide>
      </Modal>
    </>
  );
};

export default PiecesJointesBloc;
