import React, {useEffect, useState} from 'react';
import ConfigEnvironnement from '../../ConfigEnvironnement';
import {AppBar, Button, Toolbar, Tooltip, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {useResultContext} from '../../context/ResultContext';
import {useSearchContext} from '../../context/SearchContext';
import {useMissionContext} from '../../context/MissionContext';
import {ReactComponent as LogoSinappsAutoExtranetBandeOrange} from
  '../../images/logo/logo-sinapps-auto-extranet-bande-orange.svg';
import {ReactComponent as LogoSinappsAutoRdvBandeOrange} from
  '../../images/logo/logo-sinapps-auto-rdv-bande-orange.svg';
const BarreOrangeImg = require('../../images/logo/bande-orange.png');

import {UserContext, UserContextType} from '../../context/UserContext';
import useHasRole from '../../hook/useHasRole';
import {
  permittedRolesMenuFDV,
  permittedRolesMenuSIV,
  permittedRolesMenuRDV,
  permittedRolesMenuFormOM, permittedRolesFormPECBG,
} from '../../constantes/roles/Roles';
import {DetailsModel} from '../../model/detailsDossier/DetailsModel';
import {ToolbarSVP} from './ToolbarSVP';
import {MenuComponent} from './MenuComponent';
import {MenuAccount} from './MenuAccount';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {isAssureurOrExpertOrCarrossier} from '../../common/utils/UserTypeUtils';
import {ReactComponent as ChangeEntityIcon} from '../../images/icones/icn-change-people.svg';
import {PopUpEntities} from './PopUpEntities';
import {isSpecificSIVUser} from '../../common/Utils';

interface AppBarHeaderProps {
  configEnvironnement: ConfigEnvironnement;
  setUser: (user: UserContextType) => void;
  isTransparent: boolean;
}

const styles = (theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    ['@media print']: {
      display: 'none',
    },
  },
  mainBar: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    padding: '0px',
    border: 'none',
  },
  height70: {
    height: '70px',
  },
  flex: {
    display: 'flex',
  },
  entiteContainer: {
    background: theme.palette.white.main,
    width: '250px',
    color: theme.palette.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
  },
  multiEntiteTag: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    columnGap: '4px',
    right: '48px',
    backgroundColor: 'white',
    padding: '12px 12px 12px 12px',
    borderRadius: '0 0 12px 12px',
    color: theme.palette.link.main,
    alignItems: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2), inset 0px 8px 6px -7px rgba(0, 0, 0, 0.3)',
    position: 'absolute' as const,
  },
  multiEntiteTagContainer: {
    height: '32px',
  },
});

const FDV = '590101';

const isSIVItem = (hasRoleMenuSIV: boolean, user: UserContextType) =>
  hasRoleMenuSIV && !location.pathname.includes('/extranet/dossiers/formulaires-siv') &&
    user.type === 'Assureur';

const isFDVItem = (hasRoleMenuFDV: boolean, isInsideDossier: boolean, isOM: boolean | undefined,
    isAS: boolean | undefined, isDossierBG: boolean | undefined,
    docsSaisissable: string[], user: UserContextType) =>
  hasRoleMenuFDV && isInsideDossier && (Boolean(isOM) || Boolean(isAS) || Boolean(isDossierBG)) &&
    isAssureurOrExpertOrCarrossier(user.type) && docsSaisissable.includes(FDV) &&
    !location.pathname.includes('/extranet/dossiers/fdv');

const isRDVItem = (hasRoleMenuRDV: boolean, user: UserContextType) =>
  hasRoleMenuRDV && isAssureurOrExpertOrCarrossier(user.type) &&
    !location.pathname.includes('/extranet/dossiers/prise-rdv');

const isFormsItem = (hasRoleMenuForms: boolean, hasRolePECBG: boolean, user: UserContextType) =>
  hasRoleMenuForms && (!location.pathname.includes('/extranet/dossiers/formulaires') ||
    location.pathname.includes('/extranet/dossiers/formulaires-siv')) &&
    (user.type === 'Assureur' || user.type === 'Courtier'|| user.type === 'Carrossier') ||
    (user.agrementsBG?.length > 0 && hasRolePECBG);

export const AppBarHeader: React.FC<AppBarHeaderProps> = (props) => {
  const classes = useEmotionStyles(styles);

  const hasRoleMenuSIV = useHasRole(permittedRolesMenuSIV);
  const hasRoleMenuFDV = useHasRole(permittedRolesMenuFDV);
  const hasRoleMenuRDV = useHasRole(permittedRolesMenuRDV);
  const hasRoleMenuForms = useHasRole(permittedRolesMenuFormOM);
  const hasRolePECBG = useHasRole(permittedRolesFormPECBG);
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = React.useContext(UserContext);
  const actionsResult = useResultContext().actions;
  const actionsSearch = useSearchContext().actions;
  const actionsMission = useMissionContext().actions;
  const isAssistance = user.isAssistance;
  const isInsideDossier = location.pathname.includes('/extranet/dossiers/mission/');
  const isOnPriseRdv = location.pathname.includes('/extranet/dossiers/prise-rdv');
  const [showPopUpEntities, setShowPopUpEntities] = useState(false);

  useEffect(() => {
    if (showPopUpEntities) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showPopUpEntities]);

  const missionContext = useMissionContext().mission;
  const getItemMission = sessionStorage.getItem('mission');
  let mission: DetailsModel = {};
  if (missionContext === undefined) {
    if (getItemMission) {
      mission = JSON.parse(getItemMission);
    }
  } else {
    mission = missionContext;
  }
  const isOM = mission?.gestionDTO?.priseEnCharge?.isOM;
  const isAS = mission?.gestionDTO?.priseEnCharge?.isAS;
  const docsSaisissable = mission?.documentSaisissable ?? [];
  const isDossierBG = mission?.isDossierBG;
  const isSpecificSIV = isSpecificSIVUser(user.roles);

  const initialSearch = {
    numClient: user.numClient,
    userCompte: user.userCompte,
    codeAbo: user.codeAbo,
    codeService: user.service,
    nbMaxElement: '12',
  };

  const onClickPortail = () => {
    actionsResult.setResult({});
    actionsSearch.setSearch(initialSearch);
    actionsMission.setMission({});
    sessionStorage.removeItem('searchRdv');
    sessionStorage.removeItem('searchDossier');
    sessionStorage.removeItem('fromPath');
    sessionStorage.removeItem('mission');
    sessionStorage.removeItem('listRdv');
    sessionStorage.removeItem('tab');
    sessionStorage.removeItem('tabSiv');
    sessionStorage.removeItem('tokenMission');
    sessionStorage.removeItem('formSIVData');
    sessionStorage.removeItem('detailRdv');
    navigate('/extranet/portail-sinapps-auto');
  };

  const getLogo = () => {
    if (isOnPriseRdv) {
      return <LogoSinappsAutoRdvBandeOrange className={classes.height70}/>
    } else {
      return <LogoSinappsAutoExtranetBandeOrange className={classes.height70}/>
    }
  };

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={`${classes.mainBar} ${classes.height70}`}>
          <Button className={classes.link} disableRipple onClick={onClickPortail}
            disabled={props.isTransparent || isSpecificSIV}>
            <Tooltip title={'Retour au portail'}>
              {getLogo()}
            </Tooltip>
          </Button>

          <div className={`${classes.flex} ${classes.height70}`}>
            {/* Menu */}
            {!props.isTransparent && (isSIVItem(hasRoleMenuSIV, user) ||
                isFDVItem(hasRoleMenuFDV, isInsideDossier, isOM, isAS, isDossierBG, docsSaisissable, user) ||
                isRDVItem(hasRoleMenuRDV, user) || isFormsItem(hasRoleMenuForms, hasRolePECBG, user)) &&
                !isSpecificSIV &&
                <MenuComponent
                  isSIVItem={isSIVItem(hasRoleMenuSIV, user)}
                  isFDVItem={isFDVItem(hasRoleMenuFDV, isInsideDossier, isOM, isAS, isDossierBG, docsSaisissable, user)}
                  isRDVItem={isRDVItem(hasRoleMenuRDV, user)}
                  isFormsItem={isFormsItem(hasRoleMenuForms, hasRolePECBG, user)}
                />
            }

            {/* Menu utilisateur */}
            <MenuAccount
              configEnvironnement={props.configEnvironnement}
              setUser={props.setUser}
              onClickPortail={onClickPortail}
              isInsideDossier={isInsideDossier}
              disabled={props.isTransparent}
            />
            <div className={classes.flex}>
              <img src={BarreOrangeImg} className={classes.height70} alt={'separateur-orange'}/>
              <div id={'raison-sociale-header'} className={`${classes.flex} ${classes.entiteContainer}`}>
                <Typography>{user.raisonSociale}</Typography>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {showPopUpEntities &&
        <PopUpEntities
          onClosePopIn={() => setShowPopUpEntities(false)}
        />
      }

      {/* Menu mode SVP */}
      {isAssistance && <ToolbarSVP redirect={() => navigate('/extranet/portail-sinapps-auto')}/>}

      {/* Etiquette: Changer d'entité */}
      {user.filiales.length > 1 && location.pathname.endsWith('/extranet/dossiers') &&
        <div className={classes.multiEntiteTagContainer}>
          <Button
            id='btn-changer-entite'
            className={classes.multiEntiteTag}
            onClick={() => setShowPopUpEntities(true)}
          >
            <ChangeEntityIcon/>
            <div>Changer d&apos;entité</div>
          </Button>
        </div>
      }
    </div>
  )
};
