import React from 'react';
import {Grid, Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import LocaliteAutocompleteField from '../../../multiStepsForm/fields/LocaliteAutocompleteField';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';
import {FormikValues, useFormikContext} from 'formik';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';
import InputPhoneField from '../../../multiStepsForm/fields/InputPhoneField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  marginTop24: {
    marginTop: '24px',
  },
});

export const CoordonneesReparateur = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();
  const lieuExpertise = values.missionnement.lieuExpertise;
  const {coordonneesReparateur} = values;

  const isMandatory = coordonneesReparateur.nom ||
    coordonneesReparateur.telPro ||
    coordonneesReparateur.adresse ||
    coordonneesReparateur.adresseComplementaire1 ||
    coordonneesReparateur.adresseComplementaire2 ||
    coordonneesReparateur.localite.codePostal ||
    coordonneesReparateur.localite.commune ||
    coordonneesReparateur.codificationAssureur;

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Coordonnées du réparateur</Typography>
      <Grid container rowSpacing={1} rowGap={'8px'}>
        <Grid item>
          <InputTextField
            name='coordonneesReparateur.nom'
            label='Nom'
            mandatory={lieuExpertise === '2' || isMandatory}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesReparateur.telPerso'
            label='Téléphone personnel'
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesReparateur.telPro'
            label='Téléphone professionnel'
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesReparateur.telPortable'
            label='Téléphone portable'
            isPortable={true}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesReparateur.adresse'
            label='Adresse'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesReparateur.adresseComplementaire1'
            label='Adresse complémentaire 1'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesReparateur.adresseComplementaire2'
            label='Adresse complémentaire 2'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <LocaliteAutocompleteField
            id='coordonnees-reparateur-localite'
            label='Code postal - Localité'
            name='coordonneesReparateur.localite'
            isMandatory={lieuExpertise === '2' || isMandatory}
          />
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <Grid container className={classes.marginTop24}>
        <Grid item>
          <InputTextField
            name='coordonneesReparateur.codificationAssureur'
            label='Codification chez l&apos;assureur'
            mandatory={false}
            maxLength={35}
          />
        </Grid>
        <Grid item>
          <InputSelectDtField
            name='coordonneesReparateur.natureAccord'
            label='Nature de l&apos;accord'
            typeDT={'90190301'}
            domain={DOMAIN.AR2}
            mandatory={lieuExpertise === '2'}
            emptyItemLabel={'Aucune'}
          />
        </Grid>
      </Grid>
    </div>
  )
};
