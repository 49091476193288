import {useNavigate} from 'react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Button} from '@mui/material'
import React from 'react'
import {useSearchContext} from '../../../context/SearchContext'
import {useMissionContext} from '../../../context/MissionContext';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ButtonHomeProps {
  className?: string,
}

/** Design */
const styles = (theme: Theme) => ({
  buttonReturn: {
    'textAlign': 'left' as const,
    'padding': '5px',
    'fontSize': '16px',
    'letterSpacing': '0px',
    'color': theme.palette.link.main,
    'border': 'none',
    'textTransform': 'initial' as const,
    '&:hover': {
      border: 'none',
    },
  },
});

export const ButtonHome = ({className}: ButtonHomeProps) => {
  const navigate = useNavigate();
  const {search, actions} = useSearchContext();
  const actionsMission = useMissionContext().actions;
  const classes = useEmotionStyles(styles);

  return (
    <div>
      <Button
        className={`${className} ${classes.buttonReturn}`}
        variant="outlined"
        onClick={() => {
          actionsMission.setMission({});
          actions.setSearch(search!);
          sessionStorage.removeItem('searchRdv');
          sessionStorage.removeItem('fromPath');
          sessionStorage.removeItem('mission');
          sessionStorage.removeItem('listRdv');
          sessionStorage.removeItem('tab');
          sessionStorage.removeItem('tabSiv');
          sessionStorage.removeItem('tokenMission');
          sessionStorage.removeItem('formSIVData');
          sessionStorage.removeItem('detailRdv');
          const service = sessionStorage.getItem('choixService');
          sessionStorage.setItem('choixService', service ?? '');
          navigate('/extranet/dossiers')
        }}
      >
        <ArrowBackIcon/>Retour
      </Button>
    </div>
  )
};
