import React, {useEffect, useState} from 'react';
import {RefSinistre} from '../../../common/RefSinistre';
import {InputDate} from '../../../common/InputDate';
import {Typography} from '@mui/material';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import moment from 'moment';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {postSIVForm, getPreremplissageDADC} from '../../../../api/siv';
import {ISIVFormIRAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {TokenModel} from '../../../../model/common/TokenModel';
import {UserContext} from '../../../../context/UserContext';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperText, onError} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaIR} from './validationSchemaIR';
import {FooterFormSIV} from '../FooterFormSIV';
import {CardContent} from '../CardContent';
import {InformationDeclaration} from '../InformationDeclaration';
import {stylesCommon} from '../StylesCommon';

export const FormIR = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const getItemFormData = sessionStorage.getItem('formSIVData');
  const formDataStored = getItemFormData ? JSON.parse(getItemFormData) : '';
  const data = dataAccesTransparent as ISIVFormIRAccesTransparent;
  const {user} = React.useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [showPreremplirOK, setShowPreremplirOK] = useState<boolean>(false);
  const [showPreremplirKO, setShowPreremplirKO] = useState<boolean>(false);

  const initialValues = {
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), formDataStored?.referenceSinistre]),
    dateEvenement: getInitialValueSIV([data?.dateEvenement, formDataStored?.dateEvenement]),
    typePersonne: getInitialValueSIV(['pp', data?.typePersonne, formDataStored?.typePersonne]),
    identificationPP: getInitialValueSIV([data?.identificationPP, formDataStored?.identificationPP]),
    identificationPM: getInitialValueSIV([data?.identificationPM, formDataStored?.identificationPM]),
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation,
      formDataStored?.immatriculation]),
    numeroFormule: getInitialValueSIV([data?.numeroFormule, formDataStored?.numeroFormule]),
    dateCertificat: getInitialValueSIV([data?.dateCertificat, formDataStored?.dateCertificat]),
    dateDeclaration: getInitialValueSIV([moment().format('YYYY-MM-DD'), data?.dateDeclaration,
      formDataStored?.dateDeclaration]),
    heureDeclaration: getInitialValueSIV([moment().format('HH:mm'), data?.heureDeclaration,
      formDataStored?.heureDeclaration]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'IR', referenceSinistre: formikIR.values.referenceSinistre,
      dateSinistre: dateToDateText(new Date(formikIR.values.dateEvenement)), titulaireActuel: {
        isPersonnePhysique: formikIR.values.typePersonne === 'pp',
        identificationPersonne: formikIR.values.typePersonne === 'pp' ? formikIR.values.identificationPP :
          formikIR.values.identificationPM}, vehicule: {numeroImmatriculation: formikIR.values.immatriculation,
        numeroFormule: formikIR.values.numeroFormule, dateImmaticulation: formikIR.values.dateCertificat ?
          dateToDateText(new Date(formikIR.values.dateCertificat)) : ''},
      infoRefus: {dateDeclarationRefus: dateToDateText(new Date(formikIR.values.dateDeclaration)),
        heureDeclarationRefus: formikIR.values.heureDeclaration}};
    sessionStorage.setItem('formSIVData', JSON.stringify(formikIR.values));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formikIR.setFieldTouched(field, false, false)
  };

  const formikIR = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaIR,
    onSubmit: onSubmit,
  });

  const disabled = () => !!(formikIR.touched.referenceSinistre && formikIR.errors.referenceSinistre) ||
    !!(formikIR.touched.dateEvenement && formikIR.errors.dateEvenement) ||
    !!(formikIR.touched.identificationPP && formikIR.errors.identificationPP) ||
    !!(formikIR.touched.identificationPM && formikIR.errors.identificationPM) ||
    !!(formikIR.touched.immatriculation && formikIR.errors.immatriculation) ||
    !!(formikIR.touched.numeroFormule && formikIR.errors.numeroFormule) ||
    !!(formikIR.touched.dateCertificat && formikIR.errors.dateCertificat) ||
    !!(formikIR.touched.dateDeclaration && formikIR.errors.dateDeclaration) ||
    !!(formikIR.touched.heureDeclaration && formikIR.errors.heureDeclaration);

  const preremplir = () => {
    const tokenMission: TokenModel = {
      referenceSinistre: formikIR.values.referenceSinistre,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    getPreremplissageDADC(tokenMission, 'R', false).then((res) => {
      if (!res.numMissionsList) {
        formikIR.values.dateEvenement = res.dateSinistre || '';
        formikIR.values.typePersonne = 'pp';
        formikIR.values.identificationPP = res.titulaireActuel.identificationPersonne || '';
        formikIR.values.immatriculation = res.vehicule.numeroImmatriculation || '';
        formikIR.values.dateCertificat = res.vehicule.dateImmaticulation || '';
        formikIR.values.numeroFormule = res.vehicule.numeroFormule || '';
        setShowPreremplirOK(true)
      }
    }).catch(() =>
      setShowPreremplirKO(true),
    )
  };

  useEffect(() => {
    if (formikIR.values.referenceSinistre.length >= 6 && checkRefSinFormat(formikIR.values.referenceSinistre) &&
      checkRefSinSpecialChar(formikIR.values.referenceSinistre)) {
      if (!user.isTransparent) {
        preremplir()
      }
    }
  }, []);

  return (
    <div aria-hidden={true} onClick={() => {
      setShowPreremplirKO(false);
      setShowPreremplirOK(false)
    }}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'IR'}
            onChangeValue={formikIR.handleChange}
            onBlur={formikIR.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={true}
            value={formikIR.values.referenceSinistre}
            onError={onError(formikIR.touched.referenceSinistre, formikIR.errors.referenceSinistre)}
            helperText={helperText(formikIR.touched.referenceSinistre, formikIR.errors.referenceSinistre)}
          />
          <InputDate
            className={classes.textfieldSmall}
            id={'dateEvenement'}
            name={'dateEvenement'}
            formulaire={'IR'}
            isMandatory={true}
            title={'Date de l\'événement'}
            value={formikIR.values.dateEvenement}
            onChangeValue={formikIR.handleChange}
            onBlur={formikIR.handleBlur}
            onFocus={onFocus('dateEvenement')}
            onError={onError(formikIR.touched.dateEvenement, formikIR.errors.dateEvenement)}
            helperText={helperText(formikIR.touched.dateEvenement, formikIR.errors.dateEvenement)}
          />
        </div>

        {showPreremplirOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }

        {showPreremplirKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assuré</Typography>
          <PersonnePhysiqueMorale
            name={'typePersonne'}
            formulaire={'IR'}
            onChangeValue={formikIR.handleChange}
            value={formikIR.values.typePersonne}
          />

          <div className={classes.marginTop} >
            {formikIR.values.typePersonne === 'pp' &&
              <IdentificationPersonne
                className={classes.textfield}
                name={'identificationPP'}
                formulaire={'IR'}
                value={formikIR.values.identificationPP}
                typePersonne={formikIR.values.typePersonne}
                onBlur={formikIR.handleBlur}
                onFocus={onFocus('identificationPP')}
                onChangeValue={formikIR.handleChange}
                onError={onError(formikIR.touched.identificationPP, formikIR.errors.identificationPP)}
                helperText={helperText(formikIR.touched.identificationPP, formikIR.errors.identificationPP)}
              />
            }

            {formikIR.values.typePersonne === 'pm' &&
              <IdentificationPersonne
                className={classes.textfield}
                name={'identificationPM'}
                formulaire={'IR'}
                value={formikIR.values.identificationPM}
                typePersonne={formikIR.values.typePersonne}
                onBlur={formikIR.handleBlur}
                onFocus={onFocus('identificationPM')}
                onChangeValue={formikIR.handleChange}
                onError={onError(formikIR.touched.identificationPM, formikIR.errors.identificationPM)}
                helperText={helperText(formikIR.touched.identificationPM, formikIR.errors.identificationPM)}
              />
            }
          </div>
        </div>
      </div>

      <CardContent titre={`Véhicule`}
        content={
          <Vehicule
            formulaire={'IR'}
            formik={formikIR}
            entity={''}
            formikValues={{immatriculation: formikIR.values.immatriculation,
              numeroFormule: formikIR.values.numeroFormule,
              dateCertificat: formikIR.values.dateCertificat}
            }
            formikTouched={formikIR.touched}
            formikErrors={formikIR.errors}
          />
        }
      />

      <CardContent titre={`Information sur la déclaration de refus`}
        content={<InformationDeclaration formulaire={'IR'} formik={formikIR} onFocus={onFocus}/>}/>

      <FooterFormSIV
        responseReturn={responseReturn}
        isLoading={isLoading}
        disabled={disabled()}
        onClick={formikIR.submitForm}/>
    </div>
  )
};
