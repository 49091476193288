import React, {useEffect, useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import InputField from '../../../multiStepsForm/fields/InputTextField';
import InputImmatriculationField from '../../../multiStepsForm/fields/InputImmatriculationField';
import {getValeursCodes} from '../../../../../api/norme';
import {ItemGetValeursCodesModel} from '../../../../../model/common/ItemGetValeursCodesModel';
import InputSelectAutocompleteField from '../../../multiStepsForm/fields/InputSelectAutocompleteField';
import {FormikValues, useFormikContext} from 'formik';
import {immatObligatoire} from '../../model/ConstantsOM';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  switch: {
    display: 'flex',
    alignContent: 'center',
  },
  margin: {
    marginTop: '12px',
    marginBottom: '16px',
  },
  immatField: {
    width: '200px',
    marginRight: '24px',
  },
});

const InfosVehiculeBloc = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();
  const [marqueList, setMarqueList] = useState([]);

  useEffect(() => {
    getValeursCodes('AR2', '90280203').then((response) => setMarqueList(response));
  }, []);

  const marqueListSort = [...marqueList].sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Informations sur le v&eacute;hicule</Typography>
      <div className={`${classes.flex} ${classes.margin}`}>
        <div>
          <InputSelectDtField
            name='infosVehicule.genre'
            label='Genre'
            typeDT={'90270202'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
        </div>
        <div>
          <InputSelectAutocompleteField
            id='infosVehicule-marque'
            name='infosVehicule.marque'
            label='Marque'
            itemsList={marqueListSort}
            isMandatory={true}
          />
        </div>
        <div>
          <InputField
            name='infosVehicule.modele'
            label='Modèle'
            mandatory={true}
            maxLength={24}
          />
        </div>
      </div>
      <div className={classes.flex}>
        <div>
          <InputImmatriculationField className={classes.immatField}
            name='infosVehicule.immatriculation'
            label='N° immatriculation'
            disabled={values.infosVehicule.genre === 'VELO'}
            mandatory={immatObligatoire.includes(values.infosVehicule.genre)}
          />
        </div>
        <div>
          <InputField
            name='infosVehicule.serie'
            label='N° de série'
            mandatory={values.infosVehicule.genre === 'VELO'}
            maxLength={17}
          />
        </div>
      </div>
    </div>
  );
};

export default InfosVehiculeBloc;


