import React, {useEffect, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_FIELD,
} from '@mui/x-data-grid';
import {frFR} from '@mui/x-data-grid/locales';
import {permittedRolesRAD} from '../../../constantes/roles/Roles';
import useHasRole from '../../../hook/useHasRole';
import {UserContext} from '../../../context/UserContext';
import {IDocumentsResult, initialDocumentsResult, IResultLine} from '../../forms/rechercheDocuments/model/model';
import NewSlideNotification from '../../common/newNotification/NewSlideNotification';
import {getDocuments, postDocumentsRAD} from '../../../api/document';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {useGridColumns} from './gridColumns';
import {Theme} from '@emotion/react';
import {IconButton, MenuItem, Typography} from '@mui/material';
import {DocumentSearchModel} from '../../../model/rechercheDocument/DocumentSearchModel';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Loader} from '../../common/Loader';
import {RadDocumentParams} from '../../../model/rechercheDocument/RadDocumentParams';

export interface SearchResultProps {
  search: DocumentSearchModel
  isEchange?: boolean
}

interface INotification {
  theme?: 'Error' | 'Success' | 'Warning'
  message?: string
}
const styles = (theme: Theme) => ({
  loader: {
    margin: '56px',
  },
  loaderButton: {
    margin: 0,
  },
  root: {
    marginBottom: '16px',
  },
  iconSeparator: {
    fill: theme.palette.secondary.dark2,
  },
  tableHeight: {
    minHeight: '375px',
  },
  buttonBar: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '8px',
  },
  itemsPerPageBar: {
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  itemsPerPageSelect: {
    width: '80px',
    textAlign: 'center' as const,
  },
  itemsPerPageInputPadding: {
    padding: '8px',
  },
  iconBtn: {
    color: theme.palette.link.main,
    display: 'inline',
  },
  iconRoot: {
    marginRight: '0px',
    display: 'flex',
  },
  buttonsBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 150px',
    gridColumnGap: '24px',
    justifyContent: 'center',
    marginTop: '32px',
  },
  radButton: {
    justifySelf: 'end',
    borderRadius: '20px',
  },
  cancelRadButton: {
    borderRadius: '20px',
  },
});

const DocumentsSearchResult = (props: SearchResultProps) => {
  const classes = useEmotionStyles(styles);
  const {search, isEchange} = props;
  const {user} = React.useContext(UserContext);
  const hasRoleDocumentsRAD = useHasRole(permittedRolesRAD);

  // State
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [selectionModel, setSelectionModel] = useState<IResultLine[]>([]);
  const [isEnableRAD, setIsEnableRAD] = useState(false);
  const [isEnableCancelRAD, setIsEnableCancelRAD] = useState(false);
  const [pdfIsPending, setPdfIsPending] = useState<boolean>(false);
  const [currentPageSize, setCurrentPageSize] = useState<string>('50');
  const [errorPDF, setErrorPDF] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [radNotification, setRadNotification] = useState<INotification>({});
  const [radLoading, setRadLoading] = useState(false);
  const [cancelRadLoading, setCancelRadLoading] = useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [result, setResult] = React.useState<IDocumentsResult>(initialDocumentsResult);
  const [disablePreviousButton, setDisablePreviousButton] = React.useState(false);
  const [disableNextButton, setDisableNextButton] = React.useState(false);

  // Récupération des colonnes de la grille
  const gridColumns = useGridColumns({pdfIsPending, setPdfIsPending, setErrorPDF});

  useEffect(() => {
    setColumns(gridColumns);
  }, [pdfIsPending]);

  useEffect(() => {
    setSelectionModel([]);
    setIsEnableRAD(false);
    setIsEnableCancelRAD(false);
    setRadNotification({});
  }, [result])

  useEffect(() => {
    if (search) {
      setIsPending(true);
      const searchCriteria:DocumentSearchModel = {...search, nbMaxElement: currentPageSize,
        codePagination: 'D', navigationOrder: 'D'};

      getDocuments(searchCriteria)
          .then((res) => {
            if (searchCriteria.navigationOrder === 'C' && res?.list.length > 0) {
              const reversedResult = [...res.list].reverse();
              res = {...res, list: reversedResult};
            }
            setResult(res);
            res.indiceDebut ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
            res.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => setIsPending(false));
    }
  }, [search, currentPageSize]);

  const customLocaleText = {
    ...frFR.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'Aucun document ne correspond à vos critères',
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setIsEnableCancelRAD(false);
    setIsEnableRAD(false);
    const arrayElement = [];
    for (const element of newSelection) {
      const line = result?.list.find((line) => element === line.identifier);
      if (line) {
        arrayElement.push(line);
        if (line.codeStatus === 'N') {
          setIsEnableRAD(true)
        } else {
          setIsEnableCancelRAD(true);
        }
      }
    }
    setSelectionModel(arrayElement);
  };

  const handleClickRAD = () => {
    setRadLoading(true);
    setRadNotification({});
    const documentsList = selectionModel.filter((document) => document.codeStatus === 'N');
    radProcess(documentsList, 'O');
  };

  const handleClickCancelRAD = () => {
    setCancelRadLoading(true);
    setRadNotification({});
    const documentsList = selectionModel.filter((document) => document.codeStatus !== 'N');
    radProcess(documentsList, 'N');
  };

  const radProcess = (documentsList: IResultLine[], action: 'O' | 'N') => {
    postDocumentsRAD(
        documentsList.map((document) => {
          return {
            idDocument: document.identifier.slice(0, 10),
            typeDocument: document.docType,
            action: action,
            codeService: user.service,
            isAssistance: user.isAssistance,
            dateEmission: document.dateEmission,
            numClient: user.numClient,
          } as RadDocumentParams
        }),
    ).then((response) => {
      const result = response.data.filter((item: any) => item.rad).length;
      if (result === 0) {
        setRadNotification({
          theme: 'Error',
          message: 'Une erreur s\'est produite lors de ' +
            (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
        })
      } else if (result === documentsList.length) {
        updateDataGrid(response);
        setRadNotification({
          theme: 'Success',
          message: (action === 'O' ? 'Remise à disposition' :
            'Annulation de la remise à disposition') + ' effectuée',
        })
      } else {
        updateDataGrid(response);
        setRadNotification({
          theme: 'Warning',
          message: (action === 'O' ? 'La remise à disposition' :
            'l\'annulation de la remise à disposition') + ' n\'a pas été appliquée à tous les documents',
        })
      }
    }).catch(() => {
      setRadNotification({
        theme: 'Error',
        message: 'Une erreur s\'est produite lors de ' +
          (action === 'O' ? 'la remise à disposition' : 'l\'annulation de la remise à disposition'),
      })
    }).finally(() => {
      if (action === 'O') {
        setRadLoading(false);
      } else {
        setCancelRadLoading(false);
      }
    })
  };

  const updateDataGrid = (response: any) => {
    const processedDocuments: any[] = [];
    result?.list.forEach((item) => {
      const document = response.data.find((doc: any) => {
        return doc.idDocument === item.identifier.slice(0, 10) && doc.typeDocument === item.docType
      });
      if (document?.rad) {
        item.libelleStatut = document.libelleStatutDocument;
        item.codeStatus = document.codeStatutDocument;
        processedDocuments.push(document);
      }
    });
    updateSelections(processedDocuments);
  };

  const updateSelections = (processedDocuments: any[]) => {
    setIsEnableRAD(false);
    setIsEnableCancelRAD(false);
    const newModel = [...selectionModel].filter((line) => !processedDocuments.some((item) =>
      item.idDocument === line.identifier.slice(0, 10) && item.typeDocument === line.docType));
    setSelectionModel(newModel);
    newModel.forEach((line) => {
      if (line.codeStatus === 'N') {
        setIsEnableRAD(true)
      } else {
        setIsEnableCancelRAD(true);
      }
    })
  };

  const resultSize = result?.list ? result.list.length : 0;

  const handleFirstPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    setIsPending(true);
    const paginationCall = {codePagination: 'D', navigationOrder: 'D'};
    const searchFirst = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    getDocuments(searchFirst).then((result) => {
      if (result) {
        setResult(result);
        setIsPending(false);
            result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
            setDisablePreviousButton(true);
      }
    },
    )
  };
  const handlePreviousPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {isnReprise: Number.parseInt(result.list[0].identifier.slice(0, 10)),
      codePagination: 'P', navigationOrder: 'C'};
    const searchPrevious = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getDocuments(searchPrevious).then((result) => {
      if (result?.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        setResult(result);
            result.indiceFin ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
            setIsPending(false);
            setDisableNextButton(false);
      }
    },
    )
  };
  const handleNextPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {isnReprise: Number.parseInt(result.list[result.list.length - 1].identifier.slice(0, 10)),
      codePagination: 'S', navigationOrder: 'D'};
    const searchNext = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getDocuments(searchNext).then((result) => {
      if (result) {
        setResult(result);
            result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
            setIsPending(false);
            setDisablePreviousButton(false);
      }
    },
    )
  };
  const handleLastPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const paginationCall = {codePagination: 'D', navigationOrder: 'C'};
    const searchLast = {...search, ...paginationCall, nbMaxElement: currentPageSize};
    setIsPending(true);
    getDocuments(searchLast).then((result) => {
      if (result?.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        setResult(result);
        setIsPending(false);
            result.indiceDebut ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
            setDisableNextButton(true);
      }
    },
    )
  };

  if (isPending) {
    return (<Loader className={classes.loader} size={60}/>)
  } else {
    const pageSizeList = ['10', '20', '30', '40', '50'].map((pageSize: string) => {
      return (
        <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
      )
    });

    return (
      <div className={classes.root}>
        <DataGrid
          className={classes.tableHeight}
          classes={{
            iconSeparator: classes.iconSeparator,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                [GRID_CHECKBOX_SELECTION_FIELD]: hasRoleDocumentsRAD,
                claim: !!isEchange,
                mission: !!isEchange,
              },
            },
          }}
          rows={result?.list as GridRowsProp<any>}
          getRowId={(row: IResultLine) => row.identifier}
          columns={columns}
          rowHeight={80}
          columnHeaderHeight={80}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel.map((line) => line.identifier)}
          checkboxSelection
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={customLocaleText}
          hideFooter={true}
          density={'compact'}
          isRowSelectable={(params) => !radLoading && !cancelRadLoading ?
                  params.row.rad && hasRoleDocumentsRAD : false}
          paginationModel={{page: 0, pageSize: Number.parseInt(currentPageSize)}}
        />

        {resultSize > 0 &&
          <div className={classes.buttonBar}>
            <div className={classes.itemsPerPageBar}>
              <Typography>Lignes par page : </Typography>
              <TextFieldSelect id={'nb-items-page'} name={'nb-items-page'}
                className={classes.itemsPerPageSelect}
                classNameInput={classes.itemsPerPageInputPadding}
                itemsList={pageSizeList}
                value={currentPageSize}
                onChange={(event) => setCurrentPageSize(event.target.value)}/>
            </div>
            <IconButton id={'boutonPremierePage'} className={classes.iconBtn} onClick={handleFirstPage}
              disabled={disablePreviousButton}>
              <FirstPageIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonPrecedent'} className={classes.iconBtn} onClick={handlePreviousPage}
              disabled={disablePreviousButton}>
              <ArrowBackIosIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonSuivant'} className={classes.iconBtn} onClick={handleNextPage}
              disabled={disableNextButton}>
              <ArrowForwardIosIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
            <IconButton id={'boutonDernierePage'} className={classes.iconBtn} onClick={handleLastPage}
              disabled={disableNextButton}>
              <LastPageIcon classes={{root: classes.iconRoot}}/>
            </IconButton>
          </div>
        }

        {hasRoleDocumentsRAD &&
          <div className={classes.buttonsBlock}>
            {radLoading ?
                <Loader className={classes.loaderButton} size={30}/> :
              <ButtonBlue className={classes.radButton}
                id={'bouton-rad'}
                libelle={'RAD'}
                onClick={handleClickRAD}
                disabled={!isEnableRAD}
              />
            }
            {cancelRadLoading ?
                <Loader className={classes.loaderButton} size={30}/> :
                <ButtonBlue className={classes.cancelRadButton}
                  id={'bouton-annuler-rad'}
                  libelle={'Annuler RAD'}
                  onClick={handleClickCancelRAD}
                  disabled={!isEnableCancelRAD}
                />
            }
          </div>
        }

        {error &&
            <NewSlideNotification
              id={'notification-recherche-document'}
              theme={'Error'}
              storageItem={''}
              autoHideDuration={10000}
              onClose={() => setError(false)}
            >
              {'Une erreur s\'est produite lors de la recherche des documents'}
            </NewSlideNotification>
        }
        {errorPDF &&
              <NewSlideNotification
                id={'notification-recuperation-document'}
                theme={'Error'}
                storageItem={''}
                autoHideDuration={10000}
                onClose={() => setErrorPDF(false)}
              >
                {'Une erreur s\'est produite lors de la récupération du document'}
              </NewSlideNotification>
        }
        {radNotification.theme &&
              <NewSlideNotification
                id={'notification-rad-documents'}
                theme={radNotification.theme}
                storageItem={''}
                autoHideDuration={10000}
                onClose={() => setRadNotification({})}
              >
                {radNotification.message}
              </NewSlideNotification>
        }
      </div>
    );
  }
};

export default DocumentsSearchResult;
