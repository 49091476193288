import React, {useEffect} from 'react';
import {Typography} from '@mui/material';
import moment from 'moment';
import {Loader} from '../../../common/Loader';
import {ReactComponent as CustomExpIcon} from '../../../../images/icones/icones-acteurs/icn-expert.svg';
import {ReactComponent as LineIcon} from '../../../../images/icones/icn-line.svg';
import {Creneau, DayOfWeek, RowCalendar} from '../../../../model/priseRDV/CreneauModel';
import {customTheme} from '../../../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface TableCalendarProps {
  weekCalendar?: DayOfWeek[],
  rows: RowCalendar[],
  isLoading: boolean,
  selectedCreneau: string,
  expertId: string,
  handleClickCreneau: (creneau: Creneau) => void
}

interface DivCreneauProps {
  label: string,
  value: Creneau,
  selected: boolean,
  disponible: boolean,
  isExpert: boolean,
  onClick: (creneau: Creneau) => void,
}

const styles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.white.main,
    padding: '12px',
    height: '300px',
  },
  table: {
    width: '100%',
  },
  thead: {
    display: 'table',
    marginRight: '6px',
  },
  tr: {
    height: '70px',
  },
  th: {
    minWidth: '64px',
    padding: '0px',
    maxWidth: '64px',
    verticalAlign: 'top',
  },
  tbody: {
    'height': '280px',
    'display': 'block',
    'overflowY': 'auto' as const,
    'paddingTop': '6px',
    'scrollSnapAlign': 'center',
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  td: {
    border: '1px solid' + theme.palette.secondary.main,
    width: '14%',
    padding: '0px',
    margin: '0px',
  },
  today: {
    fontWeight: 'bold',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  divCreneau: {
    height: '40px',
    color: theme.palette.secondary.dark2,
    padding: '3px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disponible: {
    backgroundColor: theme.palette.white.main,
    cursor: 'pointer',
    padding: '3px',
  },
  selected: {
    backgroundColor: theme.palette.link.main,
    color: theme.palette.white.main,
    cursor: 'pointer',
    borderRadius: '4px',
  },
  indisponible: {
    backgroundColor: theme.palette.secondary.main,
  },
  line: {
    fill: theme.palette.secondary.dark2,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unavailable: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const DivCreneau = (props: DivCreneauProps) => {
  const classes = useEmotionStyles(styles);
  const {label, value, selected, isExpert, disponible, onClick} = props;

  const classToApply = `${classes.divCreneau} ${selected && classes.selected}
          ${!selected && disponible && classes.disponible} 
          ${!selected && !disponible && classes.indisponible}`;

  return (
    <div
      className={classToApply}
      onClick={() => onClick(value)}
      aria-hidden={true}
    >
      <div style={{height: '100%', display: 'flex', flexDirection: 'column',
        alignItems: label === '' ? 'center' : 'left', justifyContent: 'left'}}>
        <Typography
          style={{fontSize: '14px', minWidth: label === '' ? '' : '40px', marginTop: label === '' ? '5px' : ''}}>
          {label !== '' && label}
          {label === '' && <LineIcon className={classes.line}/>}
        </Typography>
        {disponible && isExpert &&
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <CustomExpIcon style={{width: '20px', height: '20px'}}/>
          </div>
        }
      </div>
    </div>
  )
};

const TableAgenda = (props: TableCalendarProps) => {
  const classes = useEmotionStyles(styles);
  const {weekCalendar, rows, isLoading, selectedCreneau, expertId, handleClickCreneau} = props;
  const itemEls = React.useRef<Array<HTMLTableRowElement | null>>([]);

  useEffect(() => {
    itemEls.current = itemEls.current.slice(0, rows.length);
    itemEls.current[15]?.scrollIntoView()
  }, [rows, isLoading]);

  const isToday = (day: DayOfWeek) => {
    return moment(day.id).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
  };

  const onUseRef = (ref: HTMLTableRowElement, index: number) => {
    itemEls.current[index] = ref
  }

  return (
    <div className={classes.container}>
      {!isLoading && weekCalendar && weekCalendar.length > 0 &&
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr className={classes.tr}>
            <th className={classes.th}/>
            { weekCalendar.map((dayofWeek, index) => {
              const style = !isToday(dayofWeek) ? {fontWeight: 'bold'} : {};
              const keyTh = `${index}`
              return (
                <th key={keyTh} style={{width: '14%', padding: '0px', textAlign: 'center'}}>
                  <Typography style={{marginBottom: '6px'}}>
                    {dayofWeek.label}
                  </Typography>
                  <div className={classes.flex} style={{height: '32px', marginBottom: '8px'}}>
                    <Typography
                      className={isToday(dayofWeek) ? classes.today : ''}
                      style={style}
                    >
                      {dayofWeek.dayOfMonth}
                    </Typography>
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {rows.map((row, index) => {
            const keyTr = `${index}`
            return (
              <tr
                key={keyTr}
                ref={(ref: HTMLTableRowElement) => onUseRef(ref, index)}
              >
                <td style={{minWidth: '64px', padding: '0px', maxWidth: '64px', verticalAlign: 'top',
                  display: 'flex', textAlign: 'right'}}>
                  <Typography style={{marginTop: '-12px', marginRight: '8px',
                    ...customTheme.styledTypography.libelle,
                  }}>
                    {row.id}
                  </Typography>
                  <div style={{borderTop: '1px solid' + customTheme.palette.secondary.main,
                    width: '18px', marginTop: '-1px',
                  }}>
                  </div>
                </td>
                {row.creneaux.map((crn, idx) => {
                  const keyTd = `${idx}`
                  const selected = !!selectedCreneau && selectedCreneau === crn.dateDebut;
                  const isExpert = crn.idExpertList.includes(expertId);
                  return <td key={keyTd} className={classes.td}>
                    <DivCreneau
                      label={crn.label}
                      value={crn}
                      selected={selected}
                      disponible={crn.disponible}
                      isExpert={isExpert}
                      onClick={(creneau: Creneau) => handleClickCreneau(creneau)}/>
                  </td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      }
      {!isLoading && weekCalendar && weekCalendar.length === 0 &&
      <div className={classes.unavailable}>
        <Typography>
          Aucun créneau n&apos;est disponible cette semaine
        </Typography>
      </div>
      }
      {isLoading &&
        <div className={classes.loader}>
          <Loader size={30}/>
        </div>
      }
    </div>
  );
};

export default TableAgenda;
