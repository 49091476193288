import React, {useState} from 'react';
import {Form, Formik, FormikConfig, FormikHelpers, FormikValues} from 'formik';
import {Card, CardContent, Step, StepButton, Stepper, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import FormNavigation from './FormNavigation';

interface MultiStepFormProps extends FormikConfig<FormikValues> {
    showStepper?: boolean
    children: React.ReactNode
}

/** Design */
const styles = (theme: Theme) => ({
  myStepper: {
    'padding': '24px 0px',
    '& .MuiStep-root': {
      paddingRight: '0px',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '0px',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '0px',
    },
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  typography: {
    fontSize: '14px',
    fontWeight: 600,
  },
  red: {
    color: 'red',
  },
  step: {
    marginBottom: '24px',
  },
  emptyBloc: {
    height: '72px',
  },
});

const MultiStepForm = (props: MultiStepFormProps) => {
  const {children, showStepper, initialValues, onSubmit} = props;
  const classes = useEmotionStyles(styles);
  const [stepNumber, setStepNumber] = useState(0);
  const [currentInitialValues, setCurrentInitialValues] = useState(initialValues);

  const steps = React.Children.toArray(children) as React.ReactElement[];
  const step: any = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values: FormikValues) => {
    setStepNumber(stepNumber + 1);
    setCurrentInitialValues(values)
  };

  const previous = (values: FormikValues) => {
    setStepNumber(stepNumber - 1);
    setCurrentInitialValues(values)
  };

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, actions)
    }
    if (isLastStep) {
      return onSubmit(values, actions)
    } else {
      actions.setTouched({});
      next(values)
    }
  };

  return (
    <div>
      <Formik
        initialValues={currentInitialValues}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) =>
          <Form>
            {showStepper &&
            <div className={classes.myStepper}>
              <Stepper nonLinear activeStep={stepNumber}>
                {steps.map((step, index) => {
                  return (
                    <Step key={step.key}>
                      <StepButton
                        onClick={()=> setStepNumber(index)}
                        disabled={index >= stepNumber}
                      />
                    </Step>
                  )
                })}
              </Stepper>
            </div>
            }
            {
              !showStepper && <div className={classes.emptyBloc}></div>
            }
            <Card className={classes.step}>
              <CardContent>
                {step}
                <Typography className={classes.typography}>
                  Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
                </Typography>
                <FormNavigation
                  isLastStep={isLastStep}
                  hasPrevious={stepNumber > 0}
                  handlePrevious={() => previous(formik.values)}
                />
              </CardContent>
            </Card>
          </Form>
        }
      </Formik>
    </div>
  );
};

export default MultiStepForm;

export const FormStep = ({children} : any) => children;
