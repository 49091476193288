import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {IParamsUrl} from '../model/models';
import useEmotionStyles from '../../../common/useEmotionStyles';
import Cookies from 'js-cookie';
import {ItemPdfView, itemPdfViewMapper} from './Utils';
import {getDocumentPdf, getMessageById} from '../../../api/dossier';
import {GenericError} from '../../common/errorsComponents/GenericError';
import {Loader} from '../../common/Loader';
import {UserContextType, userInitialState} from '../../../context/UserContext';
import useUser from '../../../hook/useUser';
import {jwtDecode} from 'jwt-decode';
import {AxiosError} from 'axios';
const base64 = require('base-64');

const styles = () => ({
  loader: {
    margin: '0px',
  },
});

const VuePdf = () => {
  const classes = useEmotionStyles(styles);
  const searchParams = useParams<keyof IParamsUrl>();
  const [notFound, setNotFound] = useState(false);
  const [pdfIsPending, setPdfIsPending] = React.useState<boolean>(false);
  const [dataUser, setDataUser] = useState<UserContextType>(
      {...userInitialState,
        isTransparent: true,
        isAssistance: false,
      });

  const search = JSON.parse(base64.decode(searchParams.urlParams));
  const decodedToken = jwtDecode<any>(search.accessToken);
  const userCompte = decodedToken['preferred_username'];

  const {data} = useUser({
    userCompte: userCompte,
    isAssistance: false,
    isTransparent: true,
    codeService: search.codeService,
  });
  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      setDataUser(user);
    }
  }, [data]);

  Cookies.set('token', search.accessToken);

  useEffect(() => {
    setPdfIsPending(true);
    if (dataUser.codeAbo) {
      const urlParams = {
        numClient: dataUser.numClient,
        codeService: search.codeService,
        referenceSinistre: search.referenceSinistre?.trim(),
        numeroMission: search.numeroMission?.trim(),
        idMessage: search.idDocument,
        codeGta: dataUser.codeGTA,
      }
      getMessageById(urlParams)
          .then((response) => {
            const data = {
              ...search,
              userCompte: dataUser.userCompte,
              codeGTA: dataUser.codeGTA,
              codeAbonne: dataUser.codeAbo,
            }
            const item = itemPdfViewMapper(search, response.data);
            handleGetPdf(item, data);
          }).catch((error: AxiosError) => {
            if (error.response) {
              setNotFound(true)
            }
          })
    }
  }, [dataUser]);

  const handleGetPdf = (item: ItemPdfView, data:any) => {
    if (item.isnIdentifier) {
      getDocumentPdf(item.idDocument ?? '', data, item.typeCode, item.ssType ?? '',
          item.isnIdentifier, true)
          .then((response) => {
            // Create a Blob from the PDF Stream
            const file = new Blob(
                [response],
                {type: 'application/pdf'});
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // Open the URL on new Window
            if (window.navigator.msSaveOrOpenBlob) {
              // IE11
              window.navigator.msSaveOrOpenBlob(file, 'document.pdf')
            } else {
              window.open(fileURL, '_self')
            }
            setPdfIsPending(false)
          }).catch(() => {
            setPdfIsPending(false)
            setNotFound(true)
          })
    } else {
      setNotFound(true)
    }
  };

  return (
    <>
      {pdfIsPending && !notFound &&
        <Loader className={classes.loader}/>
      }
      {notFound &&
        <GenericError errorMessage={'Document inexistant !'}/>
      }
    </>
  );
};

export default VuePdf;
