import React, {useContext, useEffect, useState} from 'react';
import InputSelectField from '../../multiStepsForm/fields/InputSelectField';
import InputTextField from '../../multiStepsForm/fields/InputTextField';
import InputImmatriculationField from '../../multiStepsForm/fields/InputImmatriculationField';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {FormikValues, useFormikContext} from 'formik';
import {UserContext} from '../../../../context/UserContext';
import {
  getDocumentsRecherchables,
  getEtatsDocumentRecherchables,
  getSousDocumentsRecherchables, getStatutsDocumentRecherchables,
} from '../../../../api/norme';
import {IElementRecherchable} from '../../../../model/common/ElementRecherchable';
import {useGetItemsList} from '../../../../common/utils/useGetItemsList';

const styles = (theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  marginLeft48: {
    marginLeft: '48px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  field: {
    'width': '480px',
    'marginRight': '24px',
    '& .Mui-disabled': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  loader: {
    marginTop: '0px',
  },
});

export const SearchFields = () => {
  const classes = useEmotionStyles(styles);
  const {values, setFieldValue}: FormikValues = useFormikContext();
  const {user} = useContext(UserContext);

  const [documentsRecherchables, setDocumentsRecherchables] = useState<IElementRecherchable[]>([]);
  const [sousDocumentsRecherchables, setSousDocumentsRecherchables] = useState<IElementRecherchable[]>([]);
  const [etatsDocumentRecherchables, setEtatsDocumentRecherchables] = useState<IElementRecherchable[]>([]);
  const [statutsDocumentRecherchables, setStatutsDocumentRecherchables] = useState<IElementRecherchable[]>([]);

  // Chargement de la liste des documents
  useEffect(() => {
    getDocumentsRecherchables(user.type)
        .then((response) => {
          setDocumentsRecherchables(response)
        })
        .catch(() => {
          return false
        })

    getEtatsDocumentRecherchables()
        .then((response) => {
          setEtatsDocumentRecherchables(response)
        }).catch(() => {
          return false
        })

    getStatutsDocumentRecherchables()
        .then((response) => {
          setStatutsDocumentRecherchables(response)
        }).catch(() => {
          return false
        })
  }, []);

  // Dès que document change : chargement de la liste des sous documents + réinitialisation du sous document à 'Tous'
  useEffect(() => {
    if (values.document.trim() !== '') {
      getSousDocumentsRecherchables(user.type, values.document)
          .then((response) => {
            setFieldValue('sousDocument', ' ');
            setSousDocumentsRecherchables(response)
          })
          .catch(() => {
            return false
          })
    }
  }, [values.document]);

  const documentsList = useGetItemsList(documentsRecherchables);
  const sousDocumentsList = useGetItemsList(sousDocumentsRecherchables);
  const etatsDocumentList = useGetItemsList(etatsDocumentRecherchables);
  const statutsDocumentList = useGetItemsList(statutsDocumentRecherchables);

  return (
    <>
      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <InputSelectField
          className={classes.field}
          name='document'
          label='Documents'
          itemsList={documentsList}
          disabled={values.numeroFacture || values.immatriculation}
        />

        {values.document.trim() && sousDocumentsRecherchables.length > 0 &&
          <div className={classes.marginLeft48}>
            <InputSelectField
              className={classes.field}
              name='sousDocument'
              label='Sous documents'
              itemsList={sousDocumentsList}
            />
          </div>
        }
      </div>

      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <InputSelectField
          className={classes.field}
          name='etat'
          label='État'
          itemsList={etatsDocumentList}
        />

        <div className={classes.marginLeft48}>
          <InputSelectField
            className={classes.field}
            name='statut'
            label='Statut'
            itemsList={statutsDocumentList}
          />
        </div>
      </div>

      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <InputTextField
          className={classes.field}
          name='numeroFacture'
          label='N° de facture'
          maxLength={17}
          disabled={values.document.trim() || values.immatriculation}
        />

        <div className={classes.marginLeft48}>
          <InputImmatriculationField
            className={classes.field}
            name='immatriculation'
            label={'N° d\'immatriculation'}
            disabled={values.document.trim() || values.numeroFacture}
          />
        </div>
      </div>
    </>
  )
};
