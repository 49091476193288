import React from 'react';
import {ReactComponent as FormsIcon} from '../../images/icones/icn-forms.svg';
import {ReactComponent as AppointmentIcon} from '../../images/icones/icn-appointment.svg';
import {InfosRendezVousModel} from '../../model/detailsDossier/RendezVousModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Divider, IconButton, Menu, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ErrorIcon from '@mui/icons-material/Error';
import {ItemMenu} from '../common/ItemMenu';

interface MenuComponentProps {
  isSIVItem: boolean,
  isFDVItem: boolean,
  isRDVItem: boolean,
  isFormsItem: boolean,
}

const styles = (theme: Theme) => ({
  iconButton: {
    padding: '12px 0px',
  },
  menuIcon: {
    margin: '0px',
    fontSize: '2.5rem',
  },
  dividerVerticalMenu: {
    backgroundColor: theme.palette.white.main,
    margin: '16px 30px',
  },
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
});

export const MenuComponent = (props: MenuComponentProps) => {
  const classes = useEmotionStyles(styles);
  const {isSIVItem, isFDVItem, isRDVItem, isFormsItem} = props;

  const isInsideDossier = location.pathname.includes('/extranet/dossiers/mission/');

  const strListRdv = sessionStorage.getItem('listRdv');
  const listRdv : InfosRendezVousModel[] = strListRdv ? JSON.parse(strListRdv) : [];

  const token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenu);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget)
    sessionStorage.removeItem('formSIVData');
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null)
  };

  const onClickSIV = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  };

  const onClickForms = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  }

  const onClickRDV = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  };

  const toLinkForms = {
    pathname: '/extranet/dossiers/formulaires/',
  }

  const toLink = {
    pathname: (isInsideDossier || location.pathname.includes('/extranet/dossiers/fdv/')) ?
      '/extranet/dossiers/formulaires-siv/' + token :
      '/extranet/dossiers/formulaires-siv',
  };

  const getLinkRdv = () => {
    if (isInsideDossier) {
      if (listRdv.length === 0) {
        return '/extranet/dossiers/prise-rdv/prendre-rdv/' + token;
      }
    }
    return '/extranet/dossiers/prise-rdv/rechercher-rdv';
  };
  const toLinkRDV = {
    pathname: getLinkRdv(),
  };

  return (
    <>
      <Tooltip title='Menu'>
        <IconButton
          id={'bouton-menu'}
          className={classes.iconButton}
          aria-label="menu"
          aria-controls="menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClickMenu}
        >
          <MenuIcon className={classes.menuIcon}/>
        </IconButton>
      </Tooltip>

      <Divider classes={{root: classes.dividerVerticalMenu}} flexItem orientation={'vertical'}/>

      <Menu
        id="menu"
        anchorEl={anchorMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu}
        onClose={handleCloseMenu}
        hidden={!isRDVItem && !isSIVItem && !isFDVItem && !isFormsItem}
      >
        {isFormsItem &&
          <ItemMenu id={'menu-formulaires'} to={toLinkForms} onClick={onClickForms}>
            <>
              <FormsIcon className={classes.icon}/>
              Formulaires
            </>
          </ItemMenu>
        }
        {isSIVItem &&
          <ItemMenu id={'menu-siv'} to={toLink} onClick={onClickSIV}>
            <>
              <InsertDriveFileIcon/>
              Gestion SIV
            </>
          </ItemMenu>
        }
        {isFDVItem &&
          <ItemMenu id={'menu-fdv'} to={'/extranet/dossiers/fdv/' + token} onClick={handleCloseMenu}>
            <>
              <ErrorIcon/>
              Fiche de vigilance
            </>
          </ItemMenu>
        }
        {isRDVItem &&
          <ItemMenu id={'menu-rdv'} to={toLinkRDV} onClick={onClickRDV}>
            <>
              <AppointmentIcon className={classes.icon}/>
              Rendez-vous
            </>
          </ItemMenu>
        }
      </Menu>
    </>
  )
};
