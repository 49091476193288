import React from 'react';
import {formulairesSIV} from '../constantes/siv/Siv';
import HelpIcon from '@mui/icons-material/Help';
import {
  permittedCommonRolesSpecificSIV,
  permittedRolesSpecificSIV,
  permittedRolesSpecificSIVConsultations,
} from '../constantes/roles/Roles';

/*
* Prend en paramètre une string représentant un numéro de téléphone au format xxxxxxxxxx, xx xx xx xx xx,
* xx.xx.xx.xx.xx, ..., vérifie si le numéro est bien formé et si oui, le retourne au format xx xx xx xx xx,
* sinon retourne la donnée
* Exemple de retour : 00 00 00 00 00
*/
export const telToScreen = (tel?: string) =>{
  if (tel) {
    const tel2 = tel.replaceAll(' ', '');
    const formatNoSpace = /\d{10}/;
    const formatWithPoint = /(\d{2}\\.){4}\d{2}/;
    if (formatNoSpace.test(tel2)) {
      return [tel2.slice(0, 2), ' ',
        tel2.slice(2, 4), ' ',
        tel2.slice(4, 6), ' ',
        tel2.slice(6, 8), ' ',
        tel2.slice(8)].join('')
    } else if (formatWithPoint.test(tel2)) {
      return tel2.replaceAll('.', ' ')
    } else {
      return tel;
    }
  } else {
    return ''
  }
};

/*
* Prend en paramètre une valeur de type string ou undefined et retourne true si elle est null, vide ou undefined
*/
export const isStringEmpty = (value : string | undefined) => {
  return value === null || value === '' || value === undefined;
};

/*
* Prend en paramètre une valeur de type array ou undefined et retourne true si elle est null, undefined ou
* si le tableau est vide
*/
export const isArrayEmpty = (value : any[] | undefined) => {
  return value === null || value?.length === 0 || value === undefined;
};

/*
* Table de correspondance codeService <--> nomService
*/
const services = [{'code': 'S082', 'libelle': 'Production'}, {'code': 'S083', 'libelle': 'Test'},
  {'code': 'S084', 'libelle': 'Certification'}, {'code': 'S085', 'libelle': 'Développement'}];
/*
* Prend en paramètre une valeur de type string correspondant au code du service et retourne le nom du service
* Exemple de retour : Développement
*/
export const getService = (service: string) => {
  return (
    services.find((serv: any) => serv.code === service)?.libelle ?? ''
  )
};

/*
* Prend un paramètre un tableau retourné par la fonction getValeursCodes() et un code d'une DT et retourne l'élément
* du tableau correspondant au code
* Exemple d'appel (éléments de calcul du SD05) :
        findElementByCodeInArray([
          {"valeur": "1", "libelle": "TOTALITE", "libelleedition": "Totalité", "etat": "actif", "fonction": null,
            "lowercase": "Totalité", "uppercase": "TOTALITE"},
          {"valeur": "2", "libelle": "VEHICULE", "libelleedition": "Véhicule", "etat": "actif", "fonction": null,
            "lowercase": "Véhicule", "uppercase": "VEHICULE"}], 1)
*/
export const findElementByCodeInArray = (array: any, code: string) => {
  return array?.find((element: any) => {
    return element.valeur === code
  })
};

/*
* Prend un paramètre un tableau retourné par la fonction getValeursCodes() et un libellé édition d'une DT et retourne
* l'élément du tableau correspondant au libellé édition
* Exemple d'appel (éléments de calcul du SD05) :
        findElementByCodeInArray([
          {"valeur": "1", "libelle": "TOTALITE", "libelleedition": "Totalité", "etat": "actif", "fonction": null,
            "lowercase": "Totalité", "uppercase": "TOTALITE"},
          {"valeur": "2", "libelle": "VEHICULE", "libelleedition": "Véhicule", "etat": "actif", "fonction": null,
            "lowercase": "Véhicule", "uppercase": "VEHICULE"}], "TOTALITE")
*/
export const findElementByLibelleInArray = (array: any, libelleedition: string) => {
  return array?.find((element: any) => {
    return element.libelleedition.toUpperCase() === libelleedition.toUpperCase()
  })
};

/**
 * Retourne un booleen pour savoir si on a droit de saisir un msg
 *
 * @param {string} msg - code du message
 * @param {string[]} listeMsg - liste de message
 * @return {boolean} response
 *
 */
export function droitSaisieMessage(msg: string, listeMsg: string[]) {
  return listeMsg ? listeMsg.includes(msg): false;
}

/*
* Prend en paramètre une valeur de type string correspondant au code du formulaire SIV et retourne le
* nom complet du formulaire
* Exemple d'appel : getLibelleSIVByCode('IT')
* Exemple de retour : Identification du titulaire du véhicule
*/
export const getLibelleSIVByCode = (code: string) => {
  const form = formulairesSIV.find((formulaire) => formulaire.code === code);
  if (form) {
    return form.libelle
  }
  return '';
};

/*
* Prend en paramètre un tableau de string et renvoie la dernière valeur non null ou vide ou undefined
* Exemple d'appel : getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation])
*/
export const getInitialValueSIV = (data: (string | undefined)[]) => {
  let result = '';
  data.forEach((d) => {
    if (d && !isStringEmpty(d)) {
      result = d;
    }
  });
  return result
};

const findValue = (object: any, entity: string, field: string) => {
  const tab = entity.split('.');
  let i = 0;
  let key = object;
  let value;
  while (i < tab.length) {
    value = key[tab[i]] ?? '';
    key = value;
    i++;
  }
  return value[field]
};

export const onError = (valueTouched: any, valueErrors: any) =>
  valueTouched && !!valueErrors || false

export const helperText = (valueTouched: any, valueErrors: any) =>
  valueTouched && valueErrors ? valueErrors : ''

export const helperTextWithIcon = (formik: any, field: string, entity?: string, classNameIcon?: string) => {
  let touched: string;
  let errors: string;
  let values: string;

  if (entity && entity !== '') {
    touched = findValue(formik.touched, entity, field);
    errors = findValue(formik.errors, entity, field);
    values = findValue(formik.values, entity, field);
  } else {
    touched = formik['touched'][field];
    errors = formik['errors'][field];
    values = formik['values'][field];
  }

  if (touched && errors) {
    if (values !== '') {
      return <>{errors} <HelpIcon className={classNameIcon ?? ''}/></>
    } else {
      return errors
    }
  } else {
    return ''
  }
};

export const onFocus = (field: string, entity: string, formik: any) => () => {
  const tab = entity !== '' ? entity.split('.') : [];
  let fieldTouched = '';

  tab.forEach((element, index) => {
    fieldTouched = fieldTouched + (index !== 0 ? '.' : '') + `${element}`;
  });
  fieldTouched = (fieldTouched !== '' ? fieldTouched + '.' : '') + `${field}`;

  formik.setFieldTouched(fieldTouched, false, false)
};

const getServicesList = (abonnements: any) => {
  return abonnements.map((abo: any) => {
    switch (abo.codeService) {
      case 'S085':
        return {codeService: 'S085', nomService: 'Développement'};
      case 'S084':
        return {codeService: 'S084', nomService: 'Certification'};
      case 'S083':
        return {codeService: 'S083', nomService: 'Test'};
      case 'S082':
        return {codeService: 'S082', nomService: 'Production'};
    }
  }).filter((x: any) => x !== null && x !== undefined);
};

export const listServices = (response: any) => getServicesList(response.compte.partenaire.abonnements);

export const isSpecificSIVUser = (roles: string[]) => {
  return roles.some((role) => permittedCommonRolesSpecificSIV.includes(role)) &&
    roles.some((role) => permittedRolesSpecificSIV.includes(role)) &&
    roles.every((role) =>
      permittedCommonRolesSpecificSIV.concat(permittedRolesSpecificSIV).includes(role));
}

export const isSpecificSIVConsultationsUser = (roles: string[]) => {
  return isSpecificSIVUser(roles) &&
    roles.every((role) =>
      permittedCommonRolesSpecificSIV.concat(permittedRolesSpecificSIVConsultations).includes(role));
}
