import React, {useEffect, useState} from 'react';
import {RefSinistre} from '../../../common/RefSinistre';
import {NumMission} from '../../../common/NumMission';
import {DialogContent, MenuItem, Modal, Typography} from '@mui/material';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {Adresse} from '../../../common/adresse/Adresse';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {ISIVFormDCAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {postSIVForm, getPreremplissageDADC} from '../../../../api/siv';
import {Loader} from '../../../common/Loader';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {TokenModel} from '../../../../model/common/TokenModel';
import {UserContext} from '../../../../context/UserContext';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {Row, SearchPersonneMorale} from '../../../common/personne/SearchPersonneMorale';
import {customTheme} from '../../../../common/GlobalTheme';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperText, helperTextWithIcon, onError} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaDC} from './validationSchemaDC';
import {FooterFormSIV} from '../FooterFormSIV';
import {CardContent} from '../CardContent';
import {InformationDeclaration} from '../InformationDeclaration';
import {IdentiteNouveauTitulaire} from './IdentiteNouveauTitulaire';
import {stylesCommon} from '../StylesCommon';

export const FormDC = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(stylesCommon);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const getItemFormData = sessionStorage.getItem('formSIVData');
  const formDataStored = getItemFormData ? JSON.parse(getItemFormData) : '';
  const data = dataAccesTransparent as ISIVFormDCAccesTransparent;
  const {user} = React.useContext(UserContext);
  const tokenFromDA = sessionStorage.getItem('tokenMission');
  const tokenDA = tokenFromDA ? JSON.parse(tokenFromDA) : {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [numMissionsList, setNumMissionsList] = useState<string[]>([]);
  const [showSelectMissions, setShowSelectMissions] = useState<boolean>(false);
  const [showSearchCorporation, setShowSearchCorporation] = useState<boolean>(false);
  const [showPreremplirDC, setShowPreremplirDC] = useState<boolean>(true);
  const [showPreremplirDCOK, setShowPreremplirDCOK] = useState<boolean>(false);
  const [showPreremplirDCKO, setShowPreremplirDCKO] = useState<boolean>(false);
  const [isLoadingRemplissage, setIsLoadingRemplissage] = useState<boolean>(false);

  useEffect(() => {
    setShowSelectMissions(numMissionsList && numMissionsList.length > 1)
  }, [numMissionsList]);
  const initialValues = {
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), tokenDA?.referenceSinistre,
      formDataStored?.referenceSinistre]),
    numeroMission: getInitialValueSIV([data?.numeroMission, mission?.infoMissionDTO?.numeroMission?.trim(),
      tokenDA?.numeroMission, formDataStored?.numeroMission]),
    titulaireActuel: {
      identificationPM: getInitialValueSIV([user.raisonSocialeInfogeo, data?.titulaireActuel?.identificationPM,
        formDataStored?.titulaireActuel?.identificationPM]),
      siren: getInitialValueSIV([user.siren, data?.titulaireActuel?.siren, formDataStored?.titulaireActuel?.siren]),
      siret: getInitialValueSIV([user.siret, data?.titulaireActuel?.siret, formDataStored?.titulaireActuel?.siret]),
    },
    titulaireNouveau: {
      typePersonne: getInitialValueSIV(['pp', data?.titulaireNouveau?.typePersonne,
        formDataStored?.titulaireNouveau?.typePersonne]),
      nom: getInitialValueSIV([data?.titulaireNouveau?.nom, formDataStored?.titulaireNouveau?.nom]),
      nomUsage: getInitialValueSIV([data?.titulaireNouveau?.nomUsage, formDataStored?.titulaireNouveau?.nomUsage]),
      prenom: getInitialValueSIV([data?.titulaireNouveau?.prenom, formDataStored?.titulaireNouveau?.prenom]),
      sexe: getInitialValueSIV([data?.titulaireNouveau?.sexe, formDataStored?.titulaireNouveau?.sexe]),
      societeCommerciale: data?.titulaireNouveau?.societeCommerciale ||
          formDataStored?.titulaireNouveau?.societeCommerciale || false,
      raisonSociale: getInitialValueSIV([data?.titulaireNouveau?.raisonSociale,
        formDataStored?.titulaireNouveau?.raisonSociale]),
      siren: getInitialValueSIV([data?.titulaireNouveau?.siren, formDataStored?.titulaireNouveau?.siren]),
      siret: getInitialValueSIV([data?.titulaireNouveau?.siret, formDataStored?.titulaireNouveau?.siret]),
      adresse: {
        numero: getInitialValueSIV([data?.titulaireNouveau?.adresse?.numero,
          formDataStored?.titulaireNouveau?.adresse?.numero]),
        indice: getInitialValueSIV([data?.titulaireNouveau?.adresse?.indice,
          formDataStored?.titulaireNouveau?.adresse?.indice]),
        type: getInitialValueSIV([data?.titulaireNouveau?.adresse?.type,
          formDataStored?.titulaireNouveau?.adresse?.type]),
        nom: getInitialValueSIV([data?.titulaireNouveau?.adresse?.nom,
          formDataStored?.titulaireNouveau?.adresse?.nom]),
        lieuDit: getInitialValueSIV([data?.titulaireNouveau?.adresse?.lieuDit,
          formDataStored?.titulaireNouveau?.adresse?.lieuDit]),
        etage: getInitialValueSIV([data?.titulaireNouveau?.adresse?.etage,
          formDataStored?.titulaireNouveau?.adresse?.etage]),
        immeuble: getInitialValueSIV([data?.titulaireNouveau?.adresse?.immeuble,
          formDataStored?.titulaireNouveau?.adresse?.immeuble]),
        codePostal: getInitialValueSIV([data?.titulaireNouveau?.adresse?.codePostal,
          formDataStored?.titulaireNouveau?.adresse?.codePostal]),
        boitePostale: getInitialValueSIV([data?.titulaireNouveau?.adresse?.boitePostale,
          formDataStored?.titulaireNouveau?.adresse?.boitePostale]),
        ville: getInitialValueSIV([data?.titulaireNouveau?.adresse?.ville,
          formDataStored?.titulaireNouveau?.adresse?.ville]),
        pays: getInitialValueSIV([data?.titulaireNouveau?.adresse?.pays,
          formDataStored?.titulaireNouveau?.adresse?.pays, 'FRANCE']),
      },
    },
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation,
      formDataStored?.immatriculation]),
    numeroVin: getInitialValueSIV([data?.numeroVin, formDataStored?.numeroVin]),
    presenceCertificat: data?.presenceCertificat || formDataStored?.presenceCertificat || true,
    numeroFormule: getInitialValueSIV([data?.numeroFormule, formDataStored?.numeroFormule]),
    dateCertificat: getInitialValueSIV([data?.dateCertificat, formDataStored?.dateCertificat]),
    dateCession: getInitialValueSIV([data?.dateCession, formDataStored?.dateCession]),
    heureCession: getInitialValueSIV([data?.heureCession, formDataStored?.heureCession]),
    dateDeclaration: getInitialValueSIV([moment().format('YYYY-MM-DD'), data?.dateDeclaration,
      formDataStored?.dateDeclaration]),
    heureDeclaration: getInitialValueSIV([moment().format('HH:mm'), data?.heureDeclaration,
      formDataStored?.heureDeclaration]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'DC', referenceSinistre: formikDC.values.referenceSinistre,
      numMissionsList: [formikDC.values.numeroMission], titulaireActuel: {isPersonnePhysique: false,
        identificationPersonne: formikDC.values.titulaireActuel.identificationPM,
        siren: formikDC.values.titulaireActuel.siren, siret: formikDC.values.titulaireActuel.siret},
      nouveauTitulaire: {isPersonnePhysique: formikDC.values.titulaireNouveau.typePersonne === 'pp',
        nom: formikDC.values.titulaireNouveau.nom, nomUsage: formikDC.values.titulaireNouveau.nomUsage,
        prenom: formikDC.values.titulaireNouveau.prenom, sexe: formikDC.values.titulaireNouveau.sexe,
        isSocieteCommerciale: formikDC.values.titulaireNouveau.societeCommerciale,
        identificationPersonne: formikDC.values.titulaireNouveau.raisonSociale,
        siren: formikDC.values.titulaireNouveau.siren, siret: formikDC.values.titulaireNouveau.siret,
        nomVoie: formikDC.values.titulaireNouveau.adresse.nom,
        numeroVoie: formikDC.values.titulaireNouveau.adresse.numero,
        type: formikDC.values.titulaireNouveau.adresse.type,
        extension: formikDC.values.titulaireNouveau.adresse.indice,
        lieuxDit: formikDC.values.titulaireNouveau.adresse.lieuDit,
        etage: formikDC.values.titulaireNouveau.adresse.etage,
        immeuble: formikDC.values.titulaireNouveau.adresse.immeuble,
        codePostal: formikDC.values.titulaireNouveau.adresse.codePostal,
        boitePostale: formikDC.values.titulaireNouveau.adresse.boitePostale,
        localite: formikDC.values.titulaireNouveau.adresse.ville, pays: formikDC.values.titulaireNouveau.adresse.pays},
      vehicule: {numeroImmatriculation: formikDC.values.immatriculation, numeroVin: formikDC.values.numeroVin,
        isPresenceCertificat: formikDC.values.presenceCertificat, numeroFormule: formikDC.values.numeroFormule,
        dateImmaticulation: formikDC.values.dateCertificat ?
            dateToDateText(new Date(formikDC.values.dateCertificat)) : ''},
      infoCession: {dateCession: dateToDateText(new Date(formikDC.values.dateCession)),
        heureCession: formikDC.values.heureCession,
        dateDeclarationCession: dateToDateText(new Date(formikDC.values.dateDeclaration)),
        heureDeclarationCession: formikDC.values.heureDeclaration}};
    sessionStorage.setItem('formSIVData', JSON.stringify(formikDC.values));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string, entity?: string) => () => {
    if (entity && entity !== '') {
      formikDC.setFieldTouched(`${entity}.${field}`, false, false)
    } else {
      formikDC.setFieldTouched(field, false, false)
    }
  };

  const formikDC = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaDC,
    onSubmit: onSubmit,
  });

  const onInputReferenceSinistre = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanSearch(
        e.target.value.length >= 6 && checkRefSinFormat(e.target.value) && checkRefSinSpecialChar(e.target.value),
    );
    setShowSelectMissions(false);
  };

  const onChangeNumeroMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
  };

  const disabledTitulaireNouveauAdresse = () =>
    !!(formikDC.touched.titulaireNouveau?.adresse?.numero && formikDC.errors.titulaireNouveau?.adresse?.numero) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.indice && formikDC.errors.titulaireNouveau?.adresse?.indice) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.type && formikDC.errors.titulaireNouveau?.adresse?.type) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.nom && formikDC.errors.titulaireNouveau?.adresse?.nom) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.lieuDit && formikDC.errors.titulaireNouveau?.adresse?.lieuDit) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.etage && formikDC.errors.titulaireNouveau?.adresse?.etage) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.immeuble && formikDC.errors.titulaireNouveau?.adresse?.immeuble) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.codePostal &&
          formikDC.errors.titulaireNouveau?.adresse?.codePostal) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.boitePostale &&
          formikDC.errors.titulaireNouveau?.adresse?.boitePostale) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.ville && formikDC.errors.titulaireNouveau?.adresse?.ville) ||
      !!(formikDC.touched.titulaireNouveau?.adresse?.pays && formikDC.errors.titulaireNouveau?.adresse?.pays)

  const disabledTitulaireNouveau = () =>
    !!(formikDC.touched.titulaireNouveau?.nom && formikDC.errors.titulaireNouveau?.nom) ||
      !!(formikDC.touched.titulaireNouveau?.nomUsage && formikDC.errors.titulaireNouveau?.nomUsage) ||
      !!(formikDC.touched.titulaireNouveau?.prenom && formikDC.errors.titulaireNouveau?.prenom) ||
      !!(formikDC.touched.titulaireNouveau?.sexe && formikDC.errors.titulaireNouveau?.sexe) ||
      !!(formikDC.touched.titulaireNouveau?.raisonSociale && formikDC.errors.titulaireNouveau?.raisonSociale) ||
      !!(formikDC.touched.titulaireNouveau?.siren && formikDC.errors.titulaireNouveau?.siren) ||
      !!(formikDC.touched.titulaireNouveau?.siret && formikDC.errors.titulaireNouveau?.siret) ||
      disabledTitulaireNouveauAdresse()

  const disabled = () => !!(formikDC.touched.referenceSinistre && formikDC.errors.referenceSinistre) ||
    !!(formikDC.touched.numeroMission && formikDC.errors.numeroMission) ||
    !!(formikDC.touched.titulaireActuel?.identificationPM && formikDC.errors.titulaireActuel?.identificationPM) ||
    !!(formikDC.touched.titulaireActuel?.siren && formikDC.errors.titulaireActuel?.siren) ||
    !!(formikDC.touched.titulaireActuel?.siret && formikDC.errors.titulaireActuel?.siret) ||
    disabledTitulaireNouveau() ||
    !!(formikDC.touched.immatriculation && formikDC.errors.immatriculation) ||
    !!(formikDC.touched.numeroVin && formikDC.errors.numeroVin) ||
    !!(formikDC.touched.numeroFormule && formikDC.errors.numeroFormule) ||
    !!(formikDC.touched.dateCertificat && formikDC.errors.dateCertificat) ||
    !!(formikDC.touched.dateCession && formikDC.errors.dateCession) ||
    !!(formikDC.touched.heureCession && formikDC.errors.heureCession) ||
    !!(formikDC.touched.dateDeclaration && formikDC.errors.dateDeclaration) ||
    !!(formikDC.touched.heureDeclaration && formikDC.errors.heureDeclaration);

  const preremplirValuesTitulaireNouveau = (res: any) => {
    formikDC.values.titulaireNouveau.typePersonne = 'pm';
    formikDC.values.titulaireNouveau.societeCommerciale = true;
    formikDC.values.titulaireNouveau.raisonSociale = res.nouveauTitulaire.identificationPersonne || '';
    formikDC.values.titulaireNouveau.siren = res.nouveauTitulaire.siren || '';
    formikDC.values.titulaireNouveau.siret = res.nouveauTitulaire.siret || '';
    formikDC.values.titulaireNouveau.adresse.numero = res.nouveauTitulaire.numeroVoie || '';
    formikDC.values.titulaireNouveau.adresse.indice = res.nouveauTitulaire.extension || '';
    formikDC.values.titulaireNouveau.adresse.type = res.nouveauTitulaire.type || '';
    formikDC.values.titulaireNouveau.adresse.nom = res.nouveauTitulaire.nomVoie || '';
    formikDC.values.titulaireNouveau.adresse.lieuDit = res.nouveauTitulaire.lieuxDit || '';
    formikDC.values.titulaireNouveau.adresse.etage = res.nouveauTitulaire.etage || '';
    formikDC.values.titulaireNouveau.adresse.immeuble = res.nouveauTitulaire.immeuble || '';
    formikDC.values.titulaireNouveau.adresse.codePostal = res.nouveauTitulaire.codePostal || '';
    formikDC.values.titulaireNouveau.adresse.boitePostale = res.nouveauTitulaire.boitePostale || '';
    formikDC.values.titulaireNouveau.adresse.ville = res.nouveauTitulaire.localite || '';
  }
  const preremplirValues = (res: any) => {
    formikDC.values.titulaireActuel.identificationPM = res.titulaireActuel.identificationPersonne || '';
    formikDC.values.titulaireActuel.siren = res.titulaireActuel.siren || '';
    formikDC.values.titulaireActuel.siret = res.titulaireActuel.siret || '';
    preremplirValuesTitulaireNouveau(res)
    formikDC.values.immatriculation = res.vehicule.numeroImmatriculation || '';
    formikDC.values.numeroVin = res.vehicule.numeroVin || '';
    formikDC.values.presenceCertificat = res.vehicule.isPresenceCertificat || false;
    formikDC.values.numeroFormule = res.vehicule.numeroFormule || '';
    formikDC.values.dateCertificat = res.vehicule.dateImmaticulation || '';

    formikDC.values.dateCession = res.infoCession.dateCession || '';
    formikDC.values.heureCession = res.infoCession.heureCession || '';
  }

  const preremplir = () => {
    const tokenFromDA = sessionStorage.getItem('tokenMission') ?
        JSON.parse(sessionStorage.getItem('tokenMission')!) : '';
    const tokenMission: TokenModel = {
      referenceSinistre: formikDC.values.referenceSinistre,
      numeroMission: formikDC.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    if (tokenMission || tokenFromDA !== '') {
      setIsLoadingRemplissage(true);
      const tokenToSend = tokenFromDA !== '' ? tokenFromDA : tokenMission;
      const isFromDA = tokenFromDA !== '';
      getPreremplissageDADC(tokenToSend, 'C', isFromDA).then((res) => {
        const numMissionsList = res.numMissionsList;
        if (numMissionsList && numMissionsList.length > 1) {
          setNumMissionsList(numMissionsList);
          setCanSearch(false)
        } else {
          preremplirValues(res)
          setShowPreremplirDCOK(true)
        }
        setIsLoadingRemplissage(false);
      }).catch(() => {
        setShowPreremplirDCKO(true);
        setIsLoadingRemplissage(false)
      })
    }
  };

  const handleSelectPersonneMorale = (row: Row) => {
    formikDC.values.titulaireNouveau.raisonSociale = row.raisonSociale;
    formikDC.values.titulaireNouveau.siren = row.siren;
    formikDC.values.titulaireNouveau.siret = row.siret;
    formikDC.values.titulaireNouveau.adresse.numero = row.numeroVoie || '';
    formikDC.values.titulaireNouveau.adresse.indice = row.indiceRepetition || '';
    formikDC.values.titulaireNouveau.adresse.type = row.typeVoie || '';
    formikDC.values.titulaireNouveau.adresse.nom = row.libelleVoie || '';
    formikDC.values.titulaireNouveau.adresse.etage = row.complementAdresse || '';
    formikDC.values.titulaireNouveau.adresse.codePostal = row.codePostal || '';
    formikDC.values.titulaireNouveau.adresse.ville = row.localite || '';
  };

  useEffect(() => {
    if (user.isTransparent) {
      setShowPreremplirDC(false)
    }
    if (formikDC.values.referenceSinistre?.length >= 6 && checkRefSinFormat(formikDC.values.referenceSinistre) &&
      checkRefSinSpecialChar(formikDC.values.referenceSinistre)) {
      if (!user.isTransparent) {
        preremplir();
      }
      setShowPreremplirDC(false)
    }
  }, []);

  return (
    <div aria-hidden={true} onClick={()=>{
      setShowPreremplirDCKO(false);
      setShowPreremplirDCOK(false)
    }}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'DC'}
            onChangeValue={formikDC.handleChange}
            onBlur={formikDC.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            onInput={(e) => onInputReferenceSinistre(e)}
            isMandatory={true}
            value={formikDC.values.referenceSinistre}
            onError={onError(formikDC.touched.referenceSinistre, formikDC.errors.referenceSinistre)}
            helperText={helperText(formikDC.touched.referenceSinistre, formikDC.errors.referenceSinistre)
            }
          />

          {showSelectMissions &&
            <div>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>
                N° de mission <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textfield}
                id={'numMissionsDC'}
                name={'numeroMission'}
                marginDense={true}
                value={formikDC.values.numeroMission}
                onChange={(e) => {
                  formikDC.handleChange(e);
                  onChangeNumeroMission(e)
                }}
                itemsList={numMissionsList.map((mission) =>
                  <MenuItem key={mission} value={mission}>{mission}</MenuItem>,
                )}
                onBlur={formikDC.handleBlur}
                onFocus={onFocus('numeroMission')}
                onError={onError(formikDC.touched.numeroMission, formikDC.errors.numeroMission)}
                helperText={helperText(formikDC.touched.numeroMission, formikDC.errors.numeroMission)}
              />
              {showSelectMissions && !formikDC.values.numeroMission &&
                <div className={classes.remplissageKO}>
                  Sélectionner un N° de mission.
                </div>
              }
            </div>
          }

          {!showSelectMissions &&
            <NumMission
              className={classes.textfield}
              name={'numeroMission'}
              formulaire={'DC'}
              value={formikDC.values.numeroMission}
              onChangeValue={formikDC.handleChange}
              onBlur={formikDC.handleBlur}
              onFocus={onFocus('numeroMission')}
              onError={onError(formikDC.touched.numeroMission, formikDC.errors.numeroMission)}
              helperText={helperText(formikDC.touched.numeroMission, formikDC.errors.numeroMission)}
            />
          }

          {showPreremplirDC &&
            <div>
              {isLoadingRemplissage ?
                <Loader className={classes.loaderPreremplir} size={30}/> :
                <ButtonBlue
                  className={classes.btnPreremplir}
                  id={'boutonPreremplirDC'}
                  libelle={'Préremplir'}
                  disabled={!canSearch}
                  onClick={() => preremplir()}
                />
              }
            </div>
          }
        </div>

        {showPreremplirDCOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }
        {showPreremplirDCKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assureur</Typography>
          <div className={classes.flex}>
            <IdentificationPersonne
              className={classes.textfield}
              name={'titulaireActuel.identificationPM'}
              formulaire={'DC'}
              value={formikDC.values.titulaireActuel.identificationPM}
              typePersonne={'pm'}
              onBlur={formikDC.handleBlur}
              onFocus={onFocus('identificationPM', 'titulaireActuel')}
              onChangeValue={formikDC.handleChange}
              onError={onError(formikDC.touched.titulaireActuel?.identificationPM,
                  formikDC.errors.titulaireActuel?.identificationPM)
              }
              helperText={helperText(formikDC.touched.titulaireActuel?.identificationPM,
                  formikDC.errors.titulaireActuel?.identificationPM)
              }
            />
            <Siren
              className={classes.textfield}
              id={'sirenTitulaire'}
              name={'titulaireActuel.siren'}
              formulaire={'DC'}
              value={formikDC.values.titulaireActuel.siren}
              isMandatory={false}
              onChangeValue={formikDC.handleChange}
              onBlur={formikDC.handleBlur}
              onFocus={onFocus('siren', 'titulaireActuel')}
              onError={onError(formikDC.touched.titulaireActuel?.siren, formikDC.errors.titulaireActuel?.siren)}
              helperText={helperTextWithIcon(formikDC, 'siren', 'titulaireActuel', classes.helpIcon)}
            />
            <Siret
              className={classes.textfield}
              id={'siretTitulaire'}
              name={'titulaireActuel.siret'}
              formulaire={'DC'}
              value={formikDC.values.titulaireActuel.siret}
              isMandatory={true}
              onChangeValue={formikDC.handleChange}
              onBlur={formikDC.handleBlur}
              onFocus={onFocus('siren', 'titulaireActuel')}
              onError={onError(formikDC.touched.titulaireActuel?.siret, formikDC.errors.titulaireActuel?.siret)}
              helperText={helperTextWithIcon(formikDC, 'siret', 'titulaireActuel', classes.helpIcon)}
            />
          </div>
        </div>
      </div>

      <CardContent titre={`Identité du nouveau titulaire`}
        content={
          <IdentiteNouveauTitulaire formikDC={formikDC} onFocus={onFocus}
            setShowSearchCorporation={setShowSearchCorporation}/>}
      />

      <CardContent titre={`Coordonnées du nouveau titulaire`}
        content={
          <Adresse
            formulaire={'DC'}
            formik={formikDC}
            entity={'titulaireNouveau.adresse'}
            formikValues={formikDC.values.titulaireNouveau.adresse}
            formikTouched={formikDC.touched.titulaireNouveau?.adresse}
            formikErrors={formikDC.errors.titulaireNouveau?.adresse}
          />}
      />

      <CardContent titre={`Véhicule`}
        content={
          <Vehicule
            formulaire={'DC'}
            formik={formikDC}
            entity={''}
            formikValues={{immatriculation: formikDC.values.immatriculation, numeroVin: formikDC.values.numeroVin,
              presenceCertificat: formikDC.values.presenceCertificat, numeroFormule: formikDC.values.numeroFormule,
              dateCertificat: formikDC.values.dateCertificat}
            }
            formikTouched={formikDC.touched}
            formikErrors={formikDC.errors}
          />
        }
      />

      <CardContent titre={`Information sur la déclaration de cession`}
        content={<InformationDeclaration formulaire={'DC'} formik={formikDC} onFocus={onFocus}/>}/>

      <FooterFormSIV
        responseReturn={responseReturn}
        isLoading={isLoading}
        disabled={disabled()}
        onClick={formikDC.submitForm}/>

      <Modal
        open={showSearchCorporation}
        aria-labelledby="modal-search">
        <DialogContent>
          <SearchPersonneMorale
            onClose={() => setShowSearchCorporation(false)}
            siren={formikDC.values.titulaireNouveau.siren}
            siret={formikDC.values.titulaireNouveau.siret}
            onSelect={handleSelectPersonneMorale}
          />
        </DialogContent>
      </Modal>
    </div>
  )
};
