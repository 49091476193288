import React from 'react';
import {Grid, MenuItem, Typography} from '@mui/material';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import InputSelectField from '../../../multiStepsForm/fields/InputSelectField';
import SwitchField from '../../../multiStepsForm/fields/SwitchField';
import {FormikValues, useFormikContext} from 'formik';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop8: {
    marginTop: '8px',
  },
  field250: {
    width: '250px',
    marginRight: '24px',
  },
  field200: {
    width: '200px',
    marginRight: '24px',
  },
  field100: {
    width: '100px',
    marginRight: '24px',
  },
  switch: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  libelleSwitch: {
    color: theme.palette.secondary.dark2,
    marginBottom: '8px',
  },
});

const getListItems = (length: number, intialIndex: number) => {
  return Array.from({length: length}, (value, index) => index + intialIndex)
      .map((item) => {
        return (
          <MenuItem
            key={item}
            value={item}>
            {item}
          </MenuItem>
        )
      })
};

const Circonstances = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();
  const {circonstances} = values;
  const isCirculation = Boolean(circonstances.typeCirconstances === '1');
  const isCirculationOrBrisGlace = Boolean(circonstances.typeCirconstances === '1' ||
      circonstances.typeCirconstances === '3');

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Évènement et circonstances</Typography>
      <Grid container rowGap={'8px'}>
        <Grid item sm={12} className={classes.flex}>
          <InputSelectDtField
            name='circonstances.nature'
            label='Nature'
            typeDT={'90070401'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          <InputTextField
            name='circonstances.date'
            type='date'
            label='Date'
            mandatory={true}
            className={classes.field200}
          />
          <InputTextField
            name='circonstances.heure'
            type='time'
            label='Heure'
            className={classes.field100}
          />
        </Grid>
        <Grid item sm={12} className={`${classes.flex} ${classes.marginTop8}`}>
          <InputTextField
            name='circonstances.lieu'
            label='Lieu'
            mandatory={true}
            maxLength={80}
          />
          <InputSelectDtField
            name='circonstances.pays'
            label='Pays'
            typeDT={'91090101'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          {values.circonstances.pays === 'FR' &&
              <InputSelectDtField
                name='circonstances.departement'
                label='Département'
                typeDT={'92250101'}
                domain={DOMAIN.AR2}
                mandatory={true}
              />
          }
        </Grid>
        <Grid item sm={12} className={`${classes.flex} ${classes.marginTop8}`}>
          <InputSelectDtField
            name='circonstances.typeCirconstances'
            label='Type de circonstances'
            typeDT={'92540101'}
            domain={DOMAIN.AR2}
            mandatory={true}
          />
          {isCirculation &&
              <>
                <InputSelectField
                  name='circonstances.position'
                  label='Position dans la file (de 1 à 99)'
                  itemsList={getListItems(99, 1)}
                  className={classes.field250}
                  emptyItemLabel='Aucun'
                />
                <div className={classes.switch}>
                  <SwitchField
                    name='circonstances.projection'
                    checked={circonstances.projection}
                  />
                  <Typography className={classes.libelleSwitch}>
                    Projection
                  </Typography>
                </div>
              </>
          }
          {isCirculationOrBrisGlace &&
              <Grid item sm={12} className={`${classes.flex} ${classes.marginTop8}`}>
                <InputSelectDtField
                  name='circonstances.vehiculeAdverse'
                  label='Véhicule adverse'
                  typeDT={'90150202'}
                  domain={DOMAIN.AR2}
                  mandatory={true}
                />
                <InputSelectField
                  name='circonstances.autresVehicules'
                  label='Véhicules autres'
                  itemsList={getListItems(7, 0)}
                  className={classes.field250}
                />
                <div className={classes.switch}>
                  <SwitchField
                    name='circonstances.autresObjets'
                    checked={values.circonstances.autresObjets}
                  />
                  <Typography className={classes.libelleSwitch}>
                    Objets autres
                  </Typography>
                </div>
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default Circonstances;
