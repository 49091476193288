import React from 'react';
import {Grid, Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import LocaliteAutocompleteField from '../../../multiStepsForm/fields/LocaliteAutocompleteField';
import InputPhoneField from '../../../multiStepsForm/fields/InputPhoneField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
});

export const CoordonneesLese = () => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Coordonnées du lésé</Typography>
      <Grid container rowSpacing={1} rowGap={'8px'}>
        <Grid item>
          <InputTextField
            name='coordonneesLese.nom'
            label='Nom'
            mandatory={true}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesLese.telPerso'
            label='Téléphone personnel'
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesLese.telPro'
            label='Téléphone professionnel'
          />
        </Grid>
        <Grid item>
          <InputPhoneField
            name='coordonneesLese.telPortable'
            label='Téléphone portable'
            isPortable={true}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesLese.adresse'
            label='Adresse'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesLese.adresseComplementaire1'
            label='Adresse complémentaire 1'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <InputTextField
            name='coordonneesLese.adresseComplementaire2'
            label='Adresse complémentaire 2'
            mandatory={false}
            maxLength={32}
          />
        </Grid>
        <Grid item>
          <LocaliteAutocompleteField
            id='coordonnees-lese-localite'
            label='Code postal - Localité'
            name='coordonneesLese.localite'
            isMandatory={true}
          />
        </Grid>
      </Grid>
    </div>
  )
};
