import React from 'react';
import {Button, Card, CardContent, Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {isSpecificSIVConsultationsUser} from '../../../common/Utils';
import {UserContext} from '../../../context/UserContext';

interface MenuFormsSIVProps {
  onChangeMenu: (menu: string) => void,
  activeMenu: string,
  hasRoleSIVConsultations: boolean,
  hasRoleSIVDeclarations: boolean,
}

const styles = (theme: Theme) => ({
  consultations: {
    marginBottom: '40px',
  },
  btnMenu: {
    width: '100%',
    justifyContent: 'left',
    textAlign: 'left' as const,
    marginBottom: '8px',
  },
  activeMenu: {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.white.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
});

export const MenuFormsSIV = (props: MenuFormsSIVProps) => {
  const {onChangeMenu, activeMenu, hasRoleSIVConsultations, hasRoleSIVDeclarations} = props;
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const isSpecificSIVConsultations = isSpecificSIVConsultationsUser(user.roles);

  return (
    <Card>
      <CardContent>
        {
          hasRoleSIVConsultations && <div className={`${hasRoleSIVDeclarations && classes.consultations}`}>
            <Typography variant={'h3'}>Consultations</Typography>
            <Button id={'boutonIT'} className={`${classes.btnMenu} ${activeMenu === 'IT' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('IT')}>
              <Typography>Identification du titulaire du véhicule</Typography>
            </Button>
            <Button id={'boutonSA'} className={`${classes.btnMenu} ${activeMenu === 'SA' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('SA')}>
              <Typography>Situation administrative</Typography>
            </Button>
            <Button id={'boutonCT'} className={`${classes.btnMenu} ${activeMenu === 'CT' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('CT')}>
              <Typography>Caractéristiques techniques</Typography>
            </Button>
          </div>
        }
        {
          hasRoleSIVDeclarations && !isSpecificSIVConsultations && <div>
            <Typography variant={'h3'}>Déclarations</Typography>
            <Button id={'boutonDA'} className={`${classes.btnMenu} ${activeMenu === 'DA' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('DA')}>
              <Typography>Achat du véhicule à l&apos;assuré</Typography>
            </Button>
            <Button id={'boutonDC'} className={`${classes.btnMenu} ${activeMenu === 'DC' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('DC')}>
              <Typography>Cession du véhicule à un professionnel</Typography>
            </Button>
            <Button id={'boutonIR'} className={`${classes.btnMenu} ${activeMenu === 'IR' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('IR')}>
              <Typography>Inscription de refus de cession du véhicule</Typography>
            </Button>
          </div>
        }
      </CardContent>
    </Card>
  )
};
